import test from '../assets/storeIcons/hanger2.png';
import sneakers from '../assets/storeIcons/sneakers.png';
import streetwear from '../assets/storeIcons/streetwear.png';
import vintage from '../assets/storeIcons/vintage.png';
/*
 * Marketplace specific configuration.
 *
 * Every filter needs to have following keys:
 * - id:     Unique id of the filter.
 * - label:  The default label of the filter.
 * - type:   String that represents one of the existing filter components:
 *           BookingDateRangeFilter, KeywordFilter, PriceFilter,
 *           SelectSingleFilter, SelectMultipleFilter.
 * - group:  Is this 'primary' or 'secondary' filter?
 *           Primary filters are visible on desktop layout by default.
 *           Secondary filters are behind "More filters" button.
 *           Read more from src/containers/SearchPage/README.md
 * - queryParamNames: Describes parameters to be used with queries
 *                    (e.g. 'price' or 'pub_amenities'). Most of these are
 *                    the same between webapp URLs and API query params.
 *                    You can't change 'dates', 'price', or 'keywords'
 *                    since those filters are fixed to a specific attribute.
 * - config: Extra configuration that the filter component needs.
 *
 * Note  1s: Labels could be tied to translation file
 *         by importing FormattedMessage:
 *         <FormattedMessage id="some.translation.key.here" />
 *
 * Note 2: If you need to add new custom filter components,
 *         you need to take those into use in:
 *         src/containers/SearchPage/FilterComponent.js
 *
 * Note 3: If you just want to create more enum filters
 *         (i.e. SelectSingleFilter, SelectMultipleFilter),
 *         you can just add more configurations with those filter types
 *         and tie them with correct extended data key
 *         (i.e. pub_<key> or meta_<key>).
 */
export const cancelOrderReason = [
  { key: 'can’t-find-the-item', label: 'Can’t find the item' },
  { key: 'changed-my-mind', label: 'Changed my mind' },
  { key: 'item-sold-on-another-platform', label: 'Item sold on another platform' },
  { key: 'other', label: 'Other' },
];

export const listingStores = [
  { key: 'clothing', icon: test, label: 'Clothing' },
  { key: 'sneakers', icon: sneakers, label: 'Sneakers' },
  { key: 'streetwear', icon: streetwear, label: 'Streetwear' },
  { key: 'vintage', icon: vintage, label: 'Vintage' },
];

export const shippingSizes = [
  { key: 'under-8-oz', label: 'Under 8 oz', text: '(T-shirt, Hats, Accessories)' },
  { key: 'under-1-lb', label: 'Under 1 lb', text: '(Tops, Blouses, Button-up)' },
  { key: 'under-3-lb', label: 'Under 3 lb', text: '(Sweatshirt, Pants, Dress)' },
  { key: 'under-5-lb', label: 'Under 5 lb', text: '(Jeans, Footwear, Bag)' },
  { key: 'under-10-lb', label: 'Under 10 lb', text: '(Outerwear, Boots, Bag)' },
];

export const availabilities = [
  { key: 'for-sale', label: 'For Sale' },
  { key: 'not-for-sale', label: 'Not For Sale' },
];

export const reportingReasons = [
  { key: 'abusive', label: 'Abusive' },
  { key: 'unfriendly', label: 'Unfriendly' },
  { key: 'fake-profile-or-items', label: 'Fake Profile or Items' },
  { key: 'scam', label: 'Scam' },
  { key: 'spamming', label: 'Spamming' },
];

export const sizes = [
  { key: 'one-size', label: 'One Size', parentKey: 'sneakers', genderKey: ['men', 'women'] },
  { key: 'custom', label: 'Custom', parentKey: 'sneakers', genderKey: ['men', 'women'] },
  { key: '4', label: ' 4', parentKey: 'sneakers', genderKey: ['men', 'women'] },
  { key: '4.5', label: ' 4.5', parentKey: 'sneakers', genderKey: ['men', 'women'] },
  { key: '5', label: ' 5', parentKey: 'sneakers', genderKey: ['men', 'women'] },
  { key: '5.5', label: ' 5.5', parentKey: 'sneakers', genderKey: ['men', 'women'] },
  { key: '6', label: ' 6', parentKey: 'sneakers', genderKey: ['men', 'women'] },
  { key: '6.5', label: ' 6.5', parentKey: 'sneakers', genderKey: ['men', 'women'] },
  { key: '7', label: ' 7', parentKey: 'sneakers', genderKey: ['men', 'women'] },
  { key: '7.5', label: ' 7.5', parentKey: 'sneakers', genderKey: ['men', 'women'] },
  { key: '8', label: ' 8', parentKey: 'sneakers', genderKey: ['men', 'women'] },
  { key: '8.5', label: ' 8.5', parentKey: 'sneakers', genderKey: ['men', 'women'] },
  { key: '9', label: ' 9', parentKey: 'sneakers', genderKey: ['men', 'women'] },
  { key: '9.5', label: ' 9.5', parentKey: 'sneakers', genderKey: ['men', 'women'] },
  { key: '10', label: ' 10', parentKey: 'sneakers', genderKey: ['men', 'women'] },
  { key: '10.5', label: ' 10.5', parentKey: 'sneakers', genderKey: ['men', 'women'] },
  { key: '11', label: ' 11', parentKey: 'sneakers', genderKey: ['men', 'women'] },
  { key: '11.5', label: ' 11.5', parentKey: 'sneakers', genderKey: ['men', 'women'] },
  { key: '12', label: ' 12', parentKey: 'sneakers', genderKey: ['men', 'women'] },
  { key: '12.5', label: ' 12.5', parentKey: 'sneakers', genderKey: ['men', 'women'] },
  { key: '13', label: ' 13', parentKey: 'sneakers', genderKey: ['men', 'women'] },
  { key: '13.5', label: ' 13.5', parentKey: 'sneakers', genderKey: ['men', 'women'] },
  { key: '14', label: ' 14', parentKey: 'sneakers', genderKey: ['men', 'women'] },
  { key: '14.5', label: ' 14.5', parentKey: 'sneakers', genderKey: ['men', 'women'] },
  { key: '15', label: ' 15', parentKey: 'sneakers', genderKey: ['men', 'women'] },
  { key: '15.5', label: ' 15.5', parentKey: 'sneakers', genderKey: ['men', 'women'] },
  { key: '16', label: ' 16', parentKey: 'sneakers', genderKey: ['men', 'women'] },

  { key: 'one-size', label: 'One Size', parentKey: 'shoes', genderKey: ['men', 'women'] },
  { key: 'custom', label: 'Custom', parentKey: 'shoes', genderKey: ['men', 'women'] },
  { key: '4', label: ' 4', parentKey: 'shoes', genderKey: ['men', 'women'] },
  { key: '4.5', label: ' 4.5', parentKey: 'shoes', genderKey: ['men', 'women'] },
  { key: '5', label: ' 5', parentKey: 'shoes', genderKey: ['men', 'women'] },
  { key: '5.5', label: ' 5.5', parentKey: 'shoes', genderKey: ['men', 'women'] },
  { key: '6', label: ' 6', parentKey: 'shoes', genderKey: ['men', 'women'] },
  { key: '6.5', label: ' 6.5', parentKey: 'shoes', genderKey: ['men', 'women'] },
  { key: '7', label: ' 7', parentKey: 'shoes', genderKey: ['men', 'women'] },
  { key: '7.5', label: ' 7.5', parentKey: 'shoes', genderKey: ['men', 'women'] },
  { key: '8', label: ' 8', parentKey: 'shoes', genderKey: ['men', 'women'] },
  { key: '8.5', label: ' 8.5', parentKey: 'shoes', genderKey: ['men', 'women'] },
  { key: '9', label: ' 9', parentKey: 'shoes', genderKey: ['men', 'women'] },
  { key: '9.5', label: ' 9.5', parentKey: 'shoes', genderKey: ['men', 'women'] },
  { key: '10', label: ' 10', parentKey: 'shoes', genderKey: ['men', 'women'] },
  { key: '10.5', label: ' 10.5', parentKey: 'shoes', genderKey: ['men', 'women'] },
  { key: '11', label: ' 11', parentKey: 'shoes', genderKey: ['men', 'women'] },
  { key: '11.5', label: ' 11.5', parentKey: 'shoes', genderKey: ['men', 'women'] },
  { key: '12', label: ' 12', parentKey: 'shoes', genderKey: ['men', 'women'] },
  { key: '12.5', label: ' 12.5', parentKey: 'shoes', genderKey: ['men', 'women'] },
  { key: '13', label: ' 13', parentKey: 'shoes', genderKey: ['men', 'women'] },
  { key: '13.5', label: ' 13.5', parentKey: 'shoes', genderKey: ['men', 'women'] },
  { key: '14', label: ' 14', parentKey: 'shoes', genderKey: ['men', 'women'] },
  { key: '14.5', label: ' 14.5', parentKey: 'shoes', genderKey: ['men', 'women'] },
  { key: '15', label: ' 15', parentKey: 'shoes', genderKey: ['men', 'women'] },
  { key: '15.5', label: ' 15.5', parentKey: 'shoes', genderKey: ['men', 'women'] },
  { key: '16', label: ' 16', parentKey: 'shoes', genderKey: ['men', 'women'] },

  { key: 'one-size', label: 'One Size', parentKey: 'accessories', genderKey: ['men', 'women'] },
  { key: 'custom', label: 'Custom', parentKey: 'accessories', genderKey: ['men', 'women'] },
  { key: 'XXS', label: 'XXS', parentKey: 'accessories', genderKey: ['men', 'women'] },
  { key: 'XS', label: 'XS', parentKey: 'accessories', genderKey: ['men', 'women'] },
  { key: 'S', label: 'S', parentKey: 'accessories', genderKey: ['men', 'women'] },
  { key: 'M', label: 'M', parentKey: 'accessories', genderKey: ['men', 'women'] },
  { key: 'L', label: 'L', parentKey: 'accessories', genderKey: ['men', 'women'] },
  { key: 'XL', label: 'XL', parentKey: 'accessories', genderKey: ['men', 'women'] },
  { key: 'XXL', label: 'XXL', parentKey: 'accessories', genderKey: ['men', 'women'] },
  { key: '0X', label: '0X', parentKey: 'accessories', genderKey: ['men', 'women'] },
  { key: '1X', label: '1X', parentKey: 'accessories', genderKey: ['men', 'women'] },
  { key: '2X', label: '2X', parentKey: 'accessories', genderKey: ['men', 'women'] },
  { key: '3X', label: '3X', parentKey: 'accessories', genderKey: ['men', 'women'] },
  { key: '4X', label: '4X', parentKey: 'accessories', genderKey: ['men', 'women'] },
  { key: '5X', label: '5X', parentKey: 'accessories', genderKey: ['men', 'women'] },

  { key: 'one-size', label: 'One Size', parentKey: 'bags', genderKey: ['men', 'women'] },
  { key: 'custom', label: 'Custom', parentKey: 'bags', genderKey: ['men', 'women'] },
  { key: 'one-size', label: 'One Size', parentKey: 'costumes', genderKey: ['men', 'women'] },
  { key: 'custom', label: 'Custom', parentKey: 'costumes', genderKey: ['men', 'women'] },
  { key: 'one-size', label: 'One Size', parentKey: 'jewelry', genderKey: ['men', 'women'] },
  { key: 'custom', label: 'Custom', parentKey: 'jewelry', genderKey: ['men', 'women'] },
  { key: 'one-size', label: 'One Size', parentKey: 'other', genderKey: ['men', 'women'] },
  { key: 'custom', label: 'Custom', parentKey: 'other', genderKey: ['men', 'women'] },

  { key: 'one-size', label: 'One Size', parentKey: 'socks-underwear', genderKey: ['men'] },
  { key: 'custom', label: 'Custom', parentKey: 'socks-underwear', genderKey: ['men'] },
  { key: 'XS', label: 'XS', parentKey: 'socks-underwear', genderKey: ['men'] },
  { key: 'S', label: 'S', parentKey: 'socks-underwear', genderKey: ['men'] },
  { key: 'M', label: 'M', parentKey: 'socks-underwear', genderKey: ['men'] },
  { key: 'L', label: 'L', parentKey: 'socks-underwear', genderKey: ['men'] },
  { key: 'XL', label: 'XL', parentKey: 'socks-underwear', genderKey: ['men'] },
  { key: 'XXL', label: 'XXL', parentKey: 'socks-underwear', genderKey: ['men'] },
  { key: '3XL', label: '3XL', parentKey: 'socks-underwear', genderKey: ['men'] },

  { key: 'one-size', label: 'One Size', parentKey: 'swim', genderKey: ['men'] },
  { key: 'custom', label: 'Custom', parentKey: 'swim', genderKey: ['men'] },
  { key: 'XS', label: 'XS', parentKey: 'swim', genderKey: ['men'] },
  { key: 'S', label: 'S', parentKey: 'swim', genderKey: ['men'] },
  { key: 'M', label: 'M', parentKey: 'swim', genderKey: ['men'] },
  { key: 'L', label: 'L', parentKey: 'swim', genderKey: ['men'] },
  { key: 'XL', label: 'XL', parentKey: 'swim', genderKey: ['men'] },
  { key: 'XXL', label: 'XXL', parentKey: 'swim', genderKey: ['men'] },
  { key: '3XL', label: '3XL', parentKey: 'swim', genderKey: ['men'] },

  { key: 'one-size', label: 'One Size', parentKey: 'suits-tuxedos', genderKey: ['men'] },
  { key: 'custom', label: 'Custom', parentKey: 'suits-tuxedos', genderKey: ['men'] },
  { key: '34S', label: '34S', parentKey: 'suits-tuxedos', genderKey: ['men'] },
  { key: '34R', label: '34R', parentKey: 'suits-tuxedos', genderKey: ['men'] },
  { key: '34L', label: '34L', parentKey: 'suits-tuxedos', genderKey: ['men'] },
  { key: '35S', label: '35S', parentKey: 'suits-tuxedos', genderKey: ['men'] },
  { key: '35R', label: '35R', parentKey: 'suits-tuxedos', genderKey: ['men'] },
  { key: '35L', label: '35L', parentKey: 'suits-tuxedos', genderKey: ['men'] },
  { key: '36S', label: '36S', parentKey: 'suits-tuxedos', genderKey: ['men'] },
  { key: '36R', label: '36R', parentKey: 'suits-tuxedos', genderKey: ['men'] },
  { key: '36L', label: '36L', parentKey: 'suits-tuxedos', genderKey: ['men'] },
  { key: '37S', label: '37S', parentKey: 'suits-tuxedos', genderKey: ['men'] },
  { key: '37R', label: '37R', parentKey: 'suits-tuxedos', genderKey: ['men'] },
  { key: '37L', label: '37L', parentKey: 'suits-tuxedos', genderKey: ['men'] },
  { key: '38S', label: '38S', parentKey: 'suits-tuxedos', genderKey: ['men'] },
  { key: '38R', label: '38R', parentKey: 'suits-tuxedos', genderKey: ['men'] },
  { key: '38L', label: '38L', parentKey: 'suits-tuxedos', genderKey: ['men'] },
  { key: '39S', label: '39S', parentKey: 'suits-tuxedos', genderKey: ['men'] },
  { key: '39R', label: '39R', parentKey: 'suits-tuxedos', genderKey: ['men'] },
  { key: '39L', label: '39L', parentKey: 'suits-tuxedos', genderKey: ['men'] },
  { key: '40S', label: '40S', parentKey: 'suits-tuxedos', genderKey: ['men'] },
  { key: '40R', label: '40R', parentKey: 'suits-tuxedos', genderKey: ['men'] },
  { key: '40L', label: '40L', parentKey: 'suits-tuxedos', genderKey: ['men'] },
  { key: '41S', label: '41S', parentKey: 'suits-tuxedos', genderKey: ['men'] },
  { key: '41R', label: '41R', parentKey: 'suits-tuxedos', genderKey: ['men'] },
  { key: '41L', label: '41L', parentKey: 'suits-tuxedos', genderKey: ['men'] },
  { key: '42S', label: '42S', parentKey: 'suits-tuxedos', genderKey: ['men'] },
  { key: '42R', label: '42R', parentKey: 'suits-tuxedos', genderKey: ['men'] },
  { key: '42L', label: '42L', parentKey: 'suits-tuxedos', genderKey: ['men'] },
  { key: '43S', label: '43S', parentKey: 'suits-tuxedos', genderKey: ['men'] },
  { key: '43R', label: '43R', parentKey: 'suits-tuxedos', genderKey: ['men'] },
  { key: '43L', label: '43L', parentKey: 'suits-tuxedos', genderKey: ['men'] },
  { key: '44S', label: '44S', parentKey: 'suits-tuxedos', genderKey: ['men'] },
  { key: '44R', label: '44R', parentKey: 'suits-tuxedos', genderKey: ['men'] },
  { key: '44L', label: '44L', parentKey: 'suits-tuxedos', genderKey: ['men'] },
  { key: '45S', label: '45S', parentKey: 'suits-tuxedos', genderKey: ['men'] },
  { key: '45R', label: '45R', parentKey: 'suits-tuxedos', genderKey: ['men'] },
  { key: '45L', label: '45L', parentKey: 'suits-tuxedos', genderKey: ['men'] },
  { key: '46S', label: '46S', parentKey: 'suits-tuxedos', genderKey: ['men'] },
  { key: '46R', label: '46R', parentKey: 'suits-tuxedos', genderKey: ['men'] },
  { key: '46L', label: '46L', parentKey: 'suits-tuxedos', genderKey: ['men'] },
  { key: '47S', label: '47S', parentKey: 'suits-tuxedos', genderKey: ['men'] },
  { key: '47R', label: '47R', parentKey: 'suits-tuxedos', genderKey: ['men'] },
  { key: '47L', label: '47L', parentKey: 'suits-tuxedos', genderKey: ['men'] },
  { key: '48S', label: '48S', parentKey: 'suits-tuxedos', genderKey: ['men'] },
  { key: '48R', label: '48R', parentKey: 'suits-tuxedos', genderKey: ['men'] },
  { key: '48L', label: '48L', parentKey: 'suits-tuxedos', genderKey: ['men'] },
  { key: '49S', label: '49S', parentKey: 'suits-tuxedos', genderKey: ['men'] },
  { key: '49R', label: '49R', parentKey: 'suits-tuxedos', genderKey: ['men'] },
  { key: '49L', label: '49L', parentKey: 'suits-tuxedos', genderKey: ['men'] },
  { key: '50S', label: '50S', parentKey: 'suits-tuxedos', genderKey: ['men'] },
  { key: '50R', label: '50R', parentKey: 'suits-tuxedos', genderKey: ['men'] },
  { key: '50L', label: '50L', parentKey: 'suits-tuxedos', genderKey: ['men'] },
  { key: '51S', label: '51S', parentKey: 'suits-tuxedos', genderKey: ['men'] },
  { key: '51R', label: '51R', parentKey: 'suits-tuxedos', genderKey: ['men'] },
  { key: '51L', label: '51L', parentKey: 'suits-tuxedos', genderKey: ['men'] },
  { key: '52S', label: '52S', parentKey: 'suits-tuxedos', genderKey: ['men'] },
  { key: '52R', label: '52R', parentKey: 'suits-tuxedos', genderKey: ['men'] },
  { key: '52L', label: '52L', parentKey: 'suits-tuxedos', genderKey: ['men'] },
  { key: '53S', label: '53S', parentKey: 'suits-tuxedos', genderKey: ['men'] },
  { key: '53R', label: '53R', parentKey: 'suits-tuxedos', genderKey: ['men'] },
  { key: '53L', label: '53L', parentKey: 'suits-tuxedos', genderKey: ['men'] },
  { key: '54S', label: '54S', parentKey: 'suits-tuxedos', genderKey: ['men'] },
  { key: '54R', label: '54R', parentKey: 'suits-tuxedos', genderKey: ['men'] },
  { key: '54L', label: '54L', parentKey: 'suits-tuxedos', genderKey: ['men'] },
  { key: '55S', label: '55S', parentKey: 'suits-tuxedos', genderKey: ['men'] },
  { key: '55R', label: '55R', parentKey: 'suits-tuxedos', genderKey: ['men'] },
  { key: '55L', label: '55L', parentKey: 'suits-tuxedos', genderKey: ['men'] },
  { key: '56S', label: '56S', parentKey: 'suits-tuxedos', genderKey: ['men'] },
  { key: '56R', label: '56R', parentKey: 'suits-tuxedos', genderKey: ['men'] },
  { key: '56L', label: '56L', parentKey: 'suits-tuxedos', genderKey: ['men'] },

  { key: 'one-size', label: 'One Size', parentKey: 'dresses', genderKey: ['women'] },
  { key: 'custom', label: 'Custom', parentKey: 'dresses', genderKey: ['women'] },
  { key: 'XXS', label: 'XXS', parentKey: 'dresses', genderKey: ['women'] },
  { key: 'XS', label: 'XS', parentKey: 'dresses', genderKey: ['women'] },
  { key: 'S', label: 'S', parentKey: 'dresses', genderKey: ['women'] },
  { key: 'M', label: 'M', parentKey: 'dresses', genderKey: ['women'] },
  { key: 'L', label: 'L', parentKey: 'dresses', genderKey: ['women'] },
  { key: 'XL', label: 'XL', parentKey: 'dresses', genderKey: ['women'] },
  { key: '00', label: '00', parentKey: 'dresses', genderKey: ['women'] },
  { key: '0', label: '0', parentKey: 'dresses', genderKey: ['women'] },
  { key: '2', label: '2', parentKey: 'dresses', genderKey: ['women'] },
  { key: '4', label: '4', parentKey: 'dresses', genderKey: ['women'] },
  { key: '6', label: '6', parentKey: 'dresses', genderKey: ['women'] },
  { key: '8', label: '8', parentKey: 'dresses', genderKey: ['women'] },
  { key: '10', label: '10', parentKey: 'dresses', genderKey: ['women'] },
  { key: '12', label: '12', parentKey: 'dresses', genderKey: ['women'] },
  { key: 'XXL', label: 'XXL', parentKey: 'dresses', genderKey: ['women'] },
  { key: 'XXXL', label: 'XXXL', parentKey: 'dresses', genderKey: ['women'] },
  { key: '0X', label: '0X', parentKey: 'dresses', genderKey: ['women'] },
  { key: '1X', label: '1X', parentKey: 'dresses', genderKey: ['women'] },
  { key: '2X', label: '2X', parentKey: 'dresses', genderKey: ['women'] },
  { key: '3X', label: '3X', parentKey: 'dresses', genderKey: ['women'] },
  { key: '4X', label: '4X', parentKey: 'dresses', genderKey: ['women'] },
  { key: '5X', label: '5X', parentKey: 'dresses', genderKey: ['women'] },
  { key: '14', label: '14', parentKey: 'dresses', genderKey: ['women'] },
  { key: '14W', label: '14W', parentKey: 'dresses', genderKey: ['women'] },
  { key: '16', label: '16', parentKey: 'dresses', genderKey: ['women'] },
  { key: '16W', label: '16W', parentKey: 'dresses', genderKey: ['women'] },
  { key: '18', label: '18', parentKey: 'dresses', genderKey: ['women'] },
  { key: '18W', label: '18W', parentKey: 'dresses', genderKey: ['women'] },
  { key: '20', label: '20', parentKey: 'dresses', genderKey: ['women'] },
  { key: '20W', label: '20W', parentKey: 'dresses', genderKey: ['women'] },
  { key: '22', label: '22', parentKey: 'dresses', genderKey: ['women'] },
  { key: '22W', label: '22W', parentKey: 'dresses', genderKey: ['women'] },
  { key: '24', label: '24', parentKey: 'dresses', genderKey: ['women'] },
  { key: '24W', label: '24W', parentKey: 'dresses', genderKey: ['women'] },
  { key: '26', label: '26', parentKey: 'dresses', genderKey: ['women'] },
  { key: '26W', label: '26W', parentKey: 'dresses', genderKey: ['women'] },
  { key: '28', label: '28', parentKey: 'dresses', genderKey: ['women'] },
  { key: '28W', label: '28W', parentKey: 'dresses', genderKey: ['women'] },
  { key: '30', label: '30', parentKey: 'dresses', genderKey: ['women'] },
  { key: '30W', label: '30W', parentKey: 'dresses', genderKey: ['women'] },
  { key: '32', label: '32', parentKey: 'dresses', genderKey: ['women'] },
  { key: '32W', label: '32W', parentKey: 'dresses', genderKey: ['women'] },
  { key: '00', label: '00', parentKey: 'dresses', genderKey: ['women'] },
  { key: '0', label: '0', parentKey: 'dresses', genderKey: ['women'] },
  { key: '1', label: '1', parentKey: 'dresses', genderKey: ['women'] },
  { key: '2', label: '2', parentKey: 'dresses', genderKey: ['women'] },
  { key: '3', label: '3', parentKey: 'dresses', genderKey: ['women'] },
  { key: '4', label: '4', parentKey: 'dresses', genderKey: ['women'] },
  { key: '5', label: '5', parentKey: 'dresses', genderKey: ['women'] },
  { key: '6', label: '6', parentKey: 'dresses', genderKey: ['women'] },
  { key: '7', label: '7', parentKey: 'dresses', genderKey: ['women'] },
  { key: '8', label: '8', parentKey: 'dresses', genderKey: ['women'] },
  { key: '9', label: '9', parentKey: 'dresses', genderKey: ['women'] },
  { key: '10', label: '10', parentKey: 'dresses', genderKey: ['women'] },
  { key: '11', label: '11', parentKey: 'dresses', genderKey: ['women'] },
  { key: '12', label: '12', parentKey: 'dresses', genderKey: ['women'] },
  { key: '13', label: '13', parentKey: 'dresses', genderKey: ['women'] },
  { key: '15', label: '15', parentKey: 'dresses', genderKey: ['women'] },
  { key: '17', label: '17', parentKey: 'dresses', genderKey: ['women'] },
  { key: 'XXS', label: 'XXS', parentKey: 'dresses', genderKey: ['women'] },
  { key: 'XS', label: 'XS', parentKey: 'dresses', genderKey: ['women'] },
  { key: 'S', label: 'S', parentKey: 'dresses', genderKey: ['women'] },
  { key: 'M', label: 'M', parentKey: 'dresses', genderKey: ['women'] },
  { key: 'L', label: 'L', parentKey: 'dresses', genderKey: ['women'] },
  { key: 'XL', label: 'XL', parentKey: 'dresses', genderKey: ['women'] },
  { key: 'XXL', label: 'XXL', parentKey: 'dresses', genderKey: ['women'] },
  { key: '00P', label: '00P', parentKey: 'dresses', genderKey: ['women'] },
  { key: '0P', label: '0P', parentKey: 'dresses', genderKey: ['women'] },
  { key: '2P', label: '2P', parentKey: 'dresses', genderKey: ['women'] },
  { key: '4P', label: '4P', parentKey: 'dresses', genderKey: ['women'] },
  { key: '6P', label: '6P', parentKey: 'dresses', genderKey: ['women'] },
  { key: '8P', label: '8P', parentKey: 'dresses', genderKey: ['women'] },
  { key: '10P', label: '10P', parentKey: 'dresses', genderKey: ['women'] },
  { key: '12P', label: '12P', parentKey: 'dresses', genderKey: ['women'] },
  { key: '14P', label: '14P', parentKey: 'dresses', genderKey: ['women'] },
  { key: '16P', label: '16P', parentKey: 'dresses', genderKey: ['women'] },
  { key: '18P', label: '18P', parentKey: 'dresses', genderKey: ['women'] },
  { key: '20P', label: '20P', parentKey: 'dresses', genderKey: ['women'] },
  { key: 'XXSP', label: 'XXSP', parentKey: 'dresses', genderKey: ['women'] },
  { key: 'XSP', label: 'XSP', parentKey: 'dresses', genderKey: ['women'] },
  { key: 'SP', label: 'SP', parentKey: 'dresses', genderKey: ['women'] },
  { key: 'MP', label: 'MP', parentKey: 'dresses', genderKey: ['women'] },
  { key: 'LP', label: 'L P', parentKey: 'dresses', genderKey: ['women'] },
  { key: 'XLP', label: 'XLP', parentKey: 'dresses', genderKey: ['women'] },
  { key: 'XXLP', label: 'XXLP', parentKey: 'dresses', genderKey: ['women'] },

  { key: 'one-size', label: 'One Size', parentKey: 'intimates-sleepwear', genderKey: ['women'] },
  { key: 'custom', label: 'Custom', parentKey: 'intimates-sleepwear', genderKey: ['women'] },
  { key: 'XXS', label: 'XXS', parentKey: 'intimates-sleepwear', genderKey: ['women'] },
  { key: 'XS', label: 'XS', parentKey: 'intimates-sleepwear', genderKey: ['women'] },
  { key: 'S', label: 'S', parentKey: 'intimates-sleepwear', genderKey: ['women'] },
  { key: 'M', label: 'M', parentKey: 'intimates-sleepwear', genderKey: ['women'] },
  { key: 'L', label: 'L', parentKey: 'intimates-sleepwear', genderKey: ['women'] },
  { key: 'XL', label: 'XL', parentKey: 'intimates-sleepwear', genderKey: ['women'] },
  { key: '30A', label: '30A', parentKey: 'intimates-sleepwear', genderKey: ['women'] },
  { key: '30B', label: '30B', parentKey: 'intimates-sleepwear', genderKey: ['women'] },
  { key: '32AA', label: '32AA', parentKey: 'intimates-sleepwear', genderKey: ['women'] },
  { key: '32A', label: '32A', parentKey: 'intimates-sleepwear', genderKey: ['women'] },
  { key: '32B', label: '32B', parentKey: 'intimates-sleepwear', genderKey: ['women'] },
  { key: '32C', label: '32C', parentKey: 'intimates-sleepwear', genderKey: ['women'] },
  { key: '32D', label: '32D', parentKey: 'intimates-sleepwear', genderKey: ['women'] },
  {
    key: '32E (DD)',
    label: '32E (DD)',

    parentKey: 'intimates-sleepwear',
    genderKey: ['women'],
  },
  {
    key: '32F (3D)',
    label: '32F (3D)',

    parentKey: 'intimates-sleepwear',
    genderKey: ['women'],
  },
  {
    key: '32G (4D)',
    label: '32G (4D)',

    parentKey: 'intimates-sleepwear',
    genderKey: ['women'],
  },
  {
    key: '32H (5D)',
    label: '32H (5D)',

    parentKey: 'intimates-sleepwear',
    genderKey: ['women'],
  },
  { key: '34AA', label: '34AA', parentKey: 'intimates-sleepwear', genderKey: ['women'] },
  { key: '34A', label: '34A', parentKey: 'intimates-sleepwear', genderKey: ['women'] },
  { key: '34B', label: '34B', parentKey: 'intimates-sleepwear', genderKey: ['women'] },
  { key: '34C', label: '34C', parentKey: 'intimates-sleepwear', genderKey: ['women'] },
  { key: '34D', label: '34D', parentKey: 'intimates-sleepwear', genderKey: ['women'] },
  {
    key: '34E (DD)',
    label: '34E (DD)',

    parentKey: 'intimates-sleepwear',
    genderKey: ['women'],
  },
  {
    key: '34F (3D)',
    label: '34F (3D)',

    parentKey: 'intimates-sleepwear',
    genderKey: ['women'],
  },
  {
    key: '34G (4D)',
    label: '34G (4D)',

    parentKey: 'intimates-sleepwear',
    genderKey: ['women'],
  },
  {
    key: '34H (5D)',
    label: '34H (5D)',

    parentKey: 'intimates-sleepwear',
    genderKey: ['women'],
  },
  { key: '36AA', label: '36AA', parentKey: 'intimates-sleepwear', genderKey: ['women'] },
  { key: '36A', label: '36A', parentKey: 'intimates-sleepwear', genderKey: ['women'] },
  { key: '36B', label: '36B', parentKey: 'intimates-sleepwear', genderKey: ['women'] },
  { key: '36C', label: '36C', parentKey: 'intimates-sleepwear', genderKey: ['women'] },
  { key: '36D', label: '36D', parentKey: 'intimates-sleepwear', genderKey: ['women'] },
  {
    key: '36E (DD)',
    label: '36E (DD)',
    parentKey: 'intimates-sleepwear',
    genderKey: ['women'],
  },
  {
    key: '36F (3D)',
    label: '36F (3D)',

    parentKey: 'intimates-sleepwear',
    genderKey: ['women'],
  },
  {
    key: '36G (4D)',
    label: '36G (4D)',

    parentKey: 'intimates-sleepwear',
    genderKey: ['women'],
  },
  {
    key: '36H (5D)',
    label: '36H (5D)',

    parentKey: 'intimates-sleepwear',
    genderKey: ['women'],
  },

  { key: '38AA', label: '38AA', parentKey: 'intimates-sleepwear', genderKey: ['women'] },
  { key: '38A', label: '38A', parentKey: 'intimates-sleepwear', genderKey: ['women'] },
  { key: '38B', label: '38B', parentKey: 'intimates-sleepwear', genderKey: ['women'] },
  { key: '38C', label: '38C', parentKey: 'intimates-sleepwear', genderKey: ['women'] },
  { key: '38D', label: '38D', parentKey: 'intimates-sleepwear', genderKey: ['women'] },
  { key: '38E', label: '38E (DD)', parentKey: 'intimates-sleepwear', genderKey: ['women'] },
  { key: '38F', label: '38F (3D)', parentKey: 'intimates-sleepwear', genderKey: ['women'] },
  { key: '38G', label: '38G (4D)', parentKey: 'intimates-sleepwear', genderKey: ['women'] },
  { key: '38H', label: '38H (5D)', parentKey: 'intimates-sleepwear', genderKey: ['women'] },
  { key: '40AA', label: '40AA', parentKey: 'intimates-sleepwear', genderKey: ['women'] },
  { key: '40A', label: '40A', parentKey: 'intimates-sleepwear', genderKey: ['women'] },
  { key: '40B', label: '40B', parentKey: 'intimates-sleepwear', genderKey: ['women'] },
  { key: '40C', label: '40C', parentKey: 'intimates-sleepwear', genderKey: ['women'] },
  { key: '40D', label: '40D', parentKey: 'intimates-sleepwear', genderKey: ['women'] },
  { key: '40E', label: '40E (DD)', parentKey: 'intimates-sleepwear', genderKey: ['women'] },
  { key: '40F', label: '40F (3D)', parentKey: 'intimates-sleepwear', genderKey: ['women'] },
  { key: '40G', label: '40G (4D)', parentKey: 'intimates-sleepwear', genderKey: ['women'] },
  { key: '40H', label: '40H (5D)', parentKey: 'intimates-sleepwear', genderKey: ['women'] },
  { key: '42AA', label: '42AA', parentKey: 'intimates-sleepwear', genderKey: ['women'] },
  { key: '42A', label: '42A', parentKey: 'intimates-sleepwear', genderKey: ['women'] },
  { key: '42B', label: '42B', parentKey: 'intimates-sleepwear', genderKey: ['women'] },
  { key: '42C', label: '42C', parentKey: 'intimates-sleepwear', genderKey: ['women'] },
  { key: '42D', label: '42D', parentKey: 'intimates-sleepwear', genderKey: ['women'] },
  { key: '42E', label: '42E (DD)', parentKey: 'intimates-sleepwear', genderKey: ['women'] },
  { key: '42F', label: '42F (3D)', parentKey: 'intimates-sleepwear', genderKey: ['women'] },
  { key: '42G', label: '42G (4D)', parentKey: 'intimates-sleepwear', genderKey: ['women'] },
  { key: '42H', label: '42H (5D)', parentKey: 'intimates-sleepwear', genderKey: ['women'] },
  { key: '44AA', label: '44AA', parentKey: 'intimates-sleepwear', genderKey: ['women'] },
  { key: '44A', label: '44A', parentKey: 'intimates-sleepwear', genderKey: ['women'] },
  { key: '44B', label: '44B', parentKey: 'intimates-sleepwear', genderKey: ['women'] },
  { key: '44C', label: '44C', parentKey: 'intimates-sleepwear', genderKey: ['women'] },
  { key: '44D', label: '44D', parentKey: 'intimates-sleepwear', genderKey: ['women'] },
  { key: '44E', label: '44E (DD)', parentKey: 'intimates-sleepwear', genderKey: ['women'] },
  { key: '44F', label: '44F (3D)', parentKey: 'intimates-sleepwear', genderKey: ['women'] },
  { key: '44G', label: '44G (4D)', parentKey: 'intimates-sleepwear', genderKey: ['women'] },
  { key: '44H', label: '44H (5D)', parentKey: 'intimates-sleepwear', genderKey: ['women'] },
  { key: '46AA', label: '46AA', parentKey: 'intimates-sleepwear', genderKey: ['women'] },
  { key: '46A', label: '46A', parentKey: 'intimates-sleepwear', genderKey: ['women'] },
  { key: '46B', label: '46B', parentKey: 'intimates-sleepwear', genderKey: ['women'] },
  { key: '46C', label: '46C', parentKey: 'intimates-sleepwear', genderKey: ['women'] },
  { key: '46D', label: '46D', parentKey: 'intimates-sleepwear', genderKey: ['women'] },
  { key: '46DD', label: '46DD', parentKey: 'intimates-sleepwear', genderKey: ['women'] },
  { key: '46F', label: '46F (3D)', parentKey: 'intimates-sleepwear', genderKey: ['women'] },
  { key: '46G', label: '46G (4D)', parentKey: 'intimates-sleepwear', genderKey: ['women'] },
  { key: '46H', label: '46H (5D)', parentKey: 'intimates-sleepwear', genderKey: ['women'] },
  { key: '48AA', label: '48AA', parentKey: 'intimates-sleepwear', genderKey: ['women'] },
  { key: '48A', label: '48A', parentKey: 'intimates-sleepwear', genderKey: ['women'] },
  { key: '48B', label: '48B', parentKey: 'intimates-sleepwear', genderKey: ['women'] },
  { key: '48C', label: '48C', parentKey: 'intimates-sleepwear', genderKey: ['women'] },
  { key: '48D', label: '48D', parentKey: 'intimates-sleepwear', genderKey: ['women'] },
  { key: '48DD', label: '48DD', parentKey: 'intimates-sleepwear', genderKey: ['women'] },
  { key: '48F', label: '48F (3D)', parentKey: 'intimates-sleepwear', genderKey: ['women'] },
  { key: '48G', label: '48G (4D)', parentKey: 'intimates-sleepwear', genderKey: ['women'] },
  { key: '48H', label: '48H (5D)', parentKey: 'intimates-sleepwear', genderKey: ['women'] },

  { key: 'one-size', label: 'One Size', parentKey: 'shirts', genderKey: ['men'] },
  { key: 'custom', label: 'Custom', parentKey: 'shirts', genderKey: ['men'] },
  { key: 'XS', label: 'XS', parentKey: 'shirts', genderKey: ['men'] },
  { key: 'S', label: 'S', parentKey: 'shirts', genderKey: ['men'] },
  { key: 'M', label: 'M', parentKey: 'shirts', genderKey: ['men'] },
  { key: 'L', label: 'L', parentKey: 'shirts', genderKey: ['men'] },
  { key: 'XL', label: 'XL', parentKey: 'shirts', genderKey: ['men'] },
  { key: 'XXL', label: 'XXL', parentKey: 'shirts', genderKey: ['men'] },
  { key: '3XL', label: '3XL', parentKey: 'shirts', genderKey: ['men'] },
  { key: '14.5', label: '14.5', parentKey: 'shirts', genderKey: ['men'] },
  { key: '15', label: '15', parentKey: 'shirts', genderKey: ['men'] },
  { key: '15.5', label: '15.5', parentKey: 'shirts', genderKey: ['men'] },
  { key: '16', label: '16', parentKey: 'shirts', genderKey: ['men'] },
  { key: '16.5', label: '16.5', parentKey: 'shirts', genderKey: ['men'] },
  { key: '17', label: '17', parentKey: 'shirts', genderKey: ['men'] },
  { key: '17.5', label: '17.5', parentKey: 'shirts', genderKey: ['men'] },
  { key: '18', label: '18', parentKey: 'shirts', genderKey: ['men'] },
  { key: '18.5', label: '18.5', parentKey: 'shirts', genderKey: ['men'] },
  { key: '19', label: '19', parentKey: 'shirts', genderKey: ['men'] },
  { key: '19.5', label: '19.5', parentKey: 'shirts', genderKey: ['men'] },
  { key: '20', label: '20', parentKey: 'shirts', genderKey: ['men'] },

  { key: 'one-size', label: 'One Size', parentKey: 'skirts', genderKey: ['women'] },
  { key: 'custom', label: 'Custom', parentKey: 'skirts', genderKey: ['women'] },
  { key: 'XXS', label: 'XXS', parentKey: 'skirts', genderKey: ['women'] },
  { key: 'XS', label: 'XS', parentKey: 'skirts', genderKey: ['women'] },
  { key: 'S', label: 'S', parentKey: 'skirts', genderKey: ['women'] },
  { key: 'M', label: 'M', parentKey: 'skirts', genderKey: ['women'] },
  { key: 'L', label: 'L', parentKey: 'skirts', genderKey: ['women'] },
  { key: 'XL', label: 'XL', parentKey: 'skirts', genderKey: ['women'] },
  { key: '23', label: '23', parentKey: 'skirts', genderKey: ['women'] },
  { key: '24', label: '24', parentKey: 'skirts', genderKey: ['women'] },
  { key: '25', label: '25', parentKey: 'skirts', genderKey: ['women'] },
  { key: '26', label: '26', parentKey: 'skirts', genderKey: ['women'] },
  { key: '27', label: '27', parentKey: 'skirts', genderKey: ['women'] },
  { key: '28', label: '28', parentKey: 'skirts', genderKey: ['women'] },
  { key: '29', label: '29', parentKey: 'skirts', genderKey: ['women'] },
  { key: '30', label: '30', parentKey: 'skirts', genderKey: ['women'] },
  { key: '31', label: '31', parentKey: 'skirts', genderKey: ['women'] },
  { key: '00', label: '00', parentKey: 'skirts', genderKey: ['women'] },
  { key: '0', label: '0', parentKey: 'skirts', genderKey: ['women'] },
  { key: '2', label: '2', parentKey: 'skirts', genderKey: ['women'] },
  { key: '4', label: '4', parentKey: 'skirts', genderKey: ['women'] },
  { key: '6', label: '6', parentKey: 'skirts', genderKey: ['women'] },
  { key: '8', label: '8', parentKey: 'skirts', genderKey: ['women'] },
  { key: '10', label: '10', parentKey: 'skirts', genderKey: ['women'] },
  { key: '12', label: '12', parentKey: 'skirts', genderKey: ['women'] },
  { key: '32', label: '32', parentKey: 'skirts', genderKey: ['women'] },
  { key: '33', label: '33', parentKey: 'skirts', genderKey: ['women'] },
  { key: '34', label: '34', parentKey: 'skirts', genderKey: ['women'] },
  { key: 'XXL', label: 'XXL', parentKey: 'skirts', genderKey: ['women'] },
  { key: 'XXXL', label: 'XXXL', parentKey: 'skirts', genderKey: ['women'] },
  { key: '0X', label: '0X', parentKey: 'skirts', genderKey: ['women'] },
  { key: '1X', label: '1X', parentKey: 'skirts', genderKey: ['women'] },
  { key: '2X', label: '2X', parentKey: 'skirts', genderKey: ['women'] },
  { key: '3X', label: '3X', parentKey: 'skirts', genderKey: ['women'] },
  { key: '4X', label: '4X', parentKey: 'skirts', genderKey: ['women'] },
  { key: '5X', label: '5X', parentKey: 'skirts', genderKey: ['women'] },
  { key: '14', label: '14', parentKey: 'skirts', genderKey: ['women'] },
  { key: '14W', label: '14W', parentKey: 'skirts', genderKey: ['women'] },
  { key: '16', label: '16', parentKey: 'skirts', genderKey: ['women'] },
  { key: '16W', label: '16W', parentKey: 'skirts', genderKey: ['women'] },
  { key: '18', label: '18', parentKey: 'skirts', genderKey: ['women'] },
  { key: '18W', label: '18W', parentKey: 'skirts', genderKey: ['women'] },
  { key: '20', label: '20', parentKey: 'skirts', genderKey: ['women'] },
  { key: '20W', label: '20W', parentKey: 'skirts', genderKey: ['women'] },
  { key: '22', label: '22', parentKey: 'skirts', genderKey: ['women'] },
  { key: '22W', label: '22W', parentKey: 'skirts', genderKey: ['women'] },
  { key: '24', label: '24', parentKey: 'skirts', genderKey: ['women'] },
  { key: '24W', label: '24W', parentKey: 'skirts', genderKey: ['women'] },
  { key: '26', label: '26', parentKey: 'skirts', genderKey: ['women'] },
  { key: '26W', label: '26W', parentKey: 'skirts', genderKey: ['women'] },
  { key: '28', label: '28', parentKey: 'skirts', genderKey: ['women'] },
  { key: '28W', label: '28W', parentKey: 'skirts', genderKey: ['women'] },
  { key: '30', label: '30', parentKey: 'skirts', genderKey: ['women'] },
  { key: '30W', label: '30W', parentKey: 'skirts', genderKey: ['women'] },
  { key: '32', label: '32', parentKey: 'skirts', genderKey: ['women'] },
  { key: '32W', label: '32W', parentKey: 'skirts', genderKey: ['women'] },
  { key: '23P', label: '23P', parentKey: 'skirts', genderKey: ['women'] },
  { key: '24P', label: '24P', parentKey: 'skirts', genderKey: ['women'] },
  { key: '25P', label: '25P', parentKey: 'skirts', genderKey: ['women'] },
  { key: '26P', label: '26P', parentKey: 'skirts', genderKey: ['women'] },
  { key: '27P', label: '27P', parentKey: 'skirts', genderKey: ['women'] },
  { key: '28P', label: '28P', parentKey: 'skirts', genderKey: ['women'] },
  { key: '29P', label: '29P', parentKey: 'skirts', genderKey: ['women'] },
  { key: '30P', label: '30P', parentKey: 'skirts', genderKey: ['women'] },
  { key: '31P', label: '31P', parentKey: 'skirts', genderKey: ['women'] },
  { key: '32P', label: '32P', parentKey: 'skirts', genderKey: ['women'] },
  { key: '33P', label: '33P', parentKey: 'skirts', genderKey: ['women'] },
  { key: '34P', label: '34P', parentKey: 'skirts', genderKey: ['women'] },
  { key: '00P', label: '00P', parentKey: 'skirts', genderKey: ['women'] },
  { key: '0P', label: '0P', parentKey: 'skirts', genderKey: ['women'] },
  { key: '2P', label: '2P', parentKey: 'skirts', genderKey: ['women'] },
  { key: '4P', label: '4P', parentKey: 'skirts', genderKey: ['women'] },
  { key: '6P', label: '6P', parentKey: 'skirts', genderKey: ['women'] },
  { key: '8P', label: '8P', parentKey: 'skirts', genderKey: ['women'] },
  { key: '10P', label: '10P', parentKey: 'skirts', genderKey: ['women'] },
  { key: '12P', label: '12P', parentKey: 'skirts', genderKey: ['women'] },
  { key: '14P', label: '14P', parentKey: 'skirts', genderKey: ['women'] },
  { key: '16P', label: '16P', parentKey: 'skirts', genderKey: ['women'] },
  { key: '18P', label: '18P', parentKey: 'skirts', genderKey: ['women'] },
  { key: '20P', label: '20P', parentKey: 'skirts', genderKey: ['women'] },
  { key: 'XXSP', label: 'XXSP', parentKey: 'skirts', genderKey: ['women'] },
  { key: 'XSP', label: 'XSP', parentKey: 'skirts', genderKey: ['women'] },
  { key: 'SP', label: 'SP', parentKey: 'skirts', genderKey: ['women'] },
  { key: 'MP', label: 'MP', parentKey: 'skirts', genderKey: ['women'] },
  { key: 'LP', label: 'LP', parentKey: 'skirts', genderKey: ['women'] },
  { key: 'XLP', label: 'XLP', parentKey: 'skirts', genderKey: ['women'] },
  { key: 'XXLP', label: 'XXLP', parentKey: 'skirts', genderKey: ['women'] },

  { key: 'one-size', label: 'One Size', parentKey: 'tops', genderKey: ['women'] },
  { key: 'custom', label: 'Custom', parentKey: 'tops', genderKey: ['women'] },
  { key: 'XXS', label: 'XXS', parentKey: 'tops', genderKey: ['women'] },
  { key: '00', label: '00', parentKey: 'tops', genderKey: ['women'] },
  { key: '0', label: '0', parentKey: 'tops', genderKey: ['women'] },
  { key: '2', label: '2', parentKey: 'tops', genderKey: ['women'] },
  { key: '4', label: '4', parentKey: 'tops', genderKey: ['women'] },
  { key: '6', label: '6', parentKey: 'tops', genderKey: ['women'] },
  { key: '8', label: '8', parentKey: 'tops', genderKey: ['women'] },
  { key: '10', label: '10', parentKey: 'tops', genderKey: ['women'] },
  { key: '12', label: '12', parentKey: 'tops', genderKey: ['women'] },
  { key: 'XXL', label: 'XXL', parentKey: 'tops', genderKey: ['women'] },
  { key: 'XXXL', label: 'XXXL', parentKey: 'tops', genderKey: ['women'] },
  { key: '0X', label: '0X', parentKey: 'tops', genderKey: ['women'] },
  { key: '1X', label: '1X', parentKey: 'tops', genderKey: ['women'] },
  { key: '2X', label: '2X', parentKey: 'tops', genderKey: ['women'] },
  { key: '3X', label: '3X', parentKey: 'tops', genderKey: ['women'] },
  { key: '4X', label: '4X', parentKey: 'tops', genderKey: ['women'] },
  { key: '5X', label: '5X', parentKey: 'tops', genderKey: ['women'] },
  { key: '14', label: '14', parentKey: 'tops', genderKey: ['women'] },
  { key: '14W', label: '14W', parentKey: 'tops', genderKey: ['women'] },
  { key: '16', label: '16', parentKey: 'tops', genderKey: ['women'] },
  { key: '16W', label: '16W', parentKey: 'tops', genderKey: ['women'] },
  { key: '18', label: '18', parentKey: 'tops', genderKey: ['women'] },
  { key: '18W', label: '18W', parentKey: 'tops', genderKey: ['women'] },
  { key: '20', label: '20', parentKey: 'tops', genderKey: ['women'] },
  { key: '20W', label: '20W', parentKey: 'tops', genderKey: ['women'] },
  { key: '22', label: '22', parentKey: 'tops', genderKey: ['women'] },
  { key: '22W', label: '22W', parentKey: 'tops', genderKey: ['women'] },
  { key: '24', label: '24', parentKey: 'tops', genderKey: ['women'] },
  { key: '24W', label: '24W', parentKey: 'tops', genderKey: ['women'] },
  { key: '26', label: '26', parentKey: 'tops', genderKey: ['women'] },
  { key: '26W', label: '26W', parentKey: 'tops', genderKey: ['women'] },
  { key: '28', label: '28', parentKey: 'tops', genderKey: ['women'] },
  { key: '28W', label: '28W', parentKey: 'tops', genderKey: ['women'] },
  { key: '30', label: '30', parentKey: 'tops', genderKey: ['women'] },
  { key: '30W', label: '30W', parentKey: 'tops', genderKey: ['women'] },
  { key: '32', label: '32', parentKey: 'tops', genderKey: ['women'] },
  { key: '32W', label: '32W', parentKey: 'tops', genderKey: ['women'] },
  { key: '00', label: '00', parentKey: 'tops', genderKey: ['women'] },
  { key: '0', label: '0', parentKey: 'tops', genderKey: ['women'] },
  { key: '1', label: '1', parentKey: 'tops', genderKey: ['women'] },
  { key: '2', label: '2', parentKey: 'tops', genderKey: ['women'] },
  { key: '3', label: '3', parentKey: 'tops', genderKey: ['women'] },
  { key: '4', label: '4', parentKey: 'tops', genderKey: ['women'] },
  { key: '5', label: '5', parentKey: 'tops', genderKey: ['women'] },
  { key: '6', label: '6', parentKey: 'tops', genderKey: ['women'] },
  { key: '7', label: '7', parentKey: 'tops', genderKey: ['women'] },
  { key: '8', label: '8', parentKey: 'tops', genderKey: ['women'] },
  { key: '9', label: '9', parentKey: 'tops', genderKey: ['women'] },
  { key: '10', label: '10', parentKey: 'tops', genderKey: ['women'] },
  { key: '11', label: '11', parentKey: 'tops', genderKey: ['women'] },
  { key: '12', label: '12', parentKey: 'tops', genderKey: ['women'] },
  { key: '13', label: '13', parentKey: 'tops', genderKey: ['women'] },
  { key: '15', label: '15', parentKey: 'tops', genderKey: ['women'] },
  { key: '17', label: '17', parentKey: 'tops', genderKey: ['women'] },
  { key: 'XXS', label: 'XXS', parentKey: 'tops', genderKey: ['women'] },
  { key: 'XS', label: 'XS', parentKey: 'tops', genderKey: ['women'] },
  { key: 'S', label: 'S', parentKey: 'tops', genderKey: ['women'] },
  { key: 'M', label: 'M', parentKey: 'tops', genderKey: ['women'] },
  { key: 'L', label: 'L', parentKey: 'tops', genderKey: ['women'] },
  { key: 'XL', label: 'XL', parentKey: 'tops', genderKey: ['women'] },
  { key: 'XXL', label: 'XXL', parentKey: 'tops', genderKey: ['women'] },
  { key: '00P', label: '00P', parentKey: 'tops', genderKey: ['women'] },
  { key: '0P', label: '0P', parentKey: 'tops', genderKey: ['women'] },
  { key: '2P', label: '2P', parentKey: 'tops', genderKey: ['women'] },
  { key: '4P', label: '4P', parentKey: 'tops', genderKey: ['women'] },
  { key: '6P', label: '6P', parentKey: 'tops', genderKey: ['women'] },
  { key: '8P', label: '8P', parentKey: 'tops', genderKey: ['women'] },
  { key: '10P', label: '10P', parentKey: 'tops', genderKey: ['women'] },
  { key: '12P', label: '12P', parentKey: 'tops', genderKey: ['women'] },
  { key: '14P', label: '14P', parentKey: 'tops', genderKey: ['women'] },
  { key: '16P', label: '16P', parentKey: 'tops', genderKey: ['women'] },
  { key: '18P', label: '18P', parentKey: 'tops', genderKey: ['women'] },
  { key: '20P', label: '20P', parentKey: 'tops', genderKey: ['women'] },
  { key: 'XXSP', label: 'XXSP', parentKey: 'tops', genderKey: ['women'] },
  { key: 'XSP', label: 'XSP', parentKey: 'tops', genderKey: ['women'] },
  { key: 'SP', label: 'SP', parentKey: 'tops', genderKey: ['women'] },
  { key: 'MP', label: 'MP', parentKey: 'tops', genderKey: ['women'] },
  { key: 'LP', label: 'LP', parentKey: 'tops', genderKey: ['women'] },
  { key: 'XLP', label: 'XLP', parentKey: 'tops', genderKey: ['women'] },
  { key: 'XXLP', label: 'XXLP', parentKey: 'tops', genderKey: ['women'] },

  { key: 'one-size', label: 'One Size', parentKey: 'swimsuits', genderKey: ['women'] },
  { key: 'custom', label: 'Custom', parentKey: 'swimsuits', genderKey: ['women'] },
  { key: 'XXS', label: 'XXS', parentKey: 'swimsuits', genderKey: ['women'] },
  { key: '00', label: '00', parentKey: 'swimsuits', genderKey: ['women'] },
  { key: '0', label: '0', parentKey: 'swimsuits', genderKey: ['women'] },
  { key: '2', label: '2', parentKey: 'swimsuits', genderKey: ['women'] },
  { key: '4', label: '4', parentKey: 'swimsuits', genderKey: ['women'] },
  { key: '6', label: '6', parentKey: 'swimsuits', genderKey: ['women'] },
  { key: '8', label: '8', parentKey: 'swimsuits', genderKey: ['women'] },
  { key: '10', label: '10', parentKey: 'swimsuits', genderKey: ['women'] },
  { key: '12', label: '12', parentKey: 'swimsuits', genderKey: ['women'] },
  { key: 'XXL', label: 'XXL', parentKey: 'swimsuits', genderKey: ['women'] },
  { key: 'XXXL', label: 'XXXL', parentKey: 'swimsuits', genderKey: ['women'] },
  { key: '0X', label: '0X', parentKey: 'swimsuits', genderKey: ['women'] },
  { key: '1X', label: '1X', parentKey: 'swimsuits', genderKey: ['women'] },
  { key: '2X', label: '2X', parentKey: 'swimsuits', genderKey: ['women'] },
  { key: '3X', label: '3X', parentKey: 'swimsuits', genderKey: ['women'] },
  { key: '4X', label: '4X', parentKey: 'swimsuits', genderKey: ['women'] },
  { key: '5X', label: '5X', parentKey: 'swimsuits', genderKey: ['women'] },
  { key: '14', label: '14', parentKey: 'swimsuits', genderKey: ['women'] },
  { key: '14W', label: '14W', parentKey: 'swimsuits', genderKey: ['women'] },
  { key: '16', label: '16', parentKey: 'swimsuits', genderKey: ['women'] },
  { key: '16W', label: '16W', parentKey: 'swimsuits', genderKey: ['women'] },
  { key: '18', label: '18', parentKey: 'swimsuits', genderKey: ['women'] },
  { key: '18W', label: '18W', parentKey: 'swimsuits', genderKey: ['women'] },
  { key: '20', label: '20', parentKey: 'swimsuits', genderKey: ['women'] },
  { key: '20W', label: '20W', parentKey: 'swimsuits', genderKey: ['women'] },
  { key: '22', label: '22', parentKey: 'swimsuits', genderKey: ['women'] },
  { key: '22W', label: '22W', parentKey: 'swimsuits', genderKey: ['women'] },
  { key: '24', label: '24', parentKey: 'swimsuits', genderKey: ['women'] },
  { key: '24W', label: '24W', parentKey: 'swimsuits', genderKey: ['women'] },
  { key: '26', label: '26', parentKey: 'swimsuits', genderKey: ['women'] },
  { key: '26W', label: '26W', parentKey: 'swimsuits', genderKey: ['women'] },
  { key: '28', label: '28', parentKey: 'swimsuits', genderKey: ['women'] },
  { key: '28W', label: '28W', parentKey: 'swimsuits', genderKey: ['women'] },
  { key: '30', label: '30', parentKey: 'swimsuits', genderKey: ['women'] },
  { key: '30W', label: '30W', parentKey: 'swimsuits', genderKey: ['women'] },
  { key: '32', label: '32', parentKey: 'swimsuits', genderKey: ['women'] },
  { key: '32W', label: '32W', parentKey: 'swimsuits', genderKey: ['women'] },
  { key: '00', label: '00', parentKey: 'swimsuits', genderKey: ['women'] },
  { key: '0', label: '0', parentKey: 'swimsuits', genderKey: ['women'] },
  { key: '1', label: '1', parentKey: 'swimsuits', genderKey: ['women'] },
  { key: '2', label: '2', parentKey: 'swimsuits', genderKey: ['women'] },
  { key: '3', label: '3', parentKey: 'swimsuits', genderKey: ['women'] },
  { key: '4', label: '4', parentKey: 'swimsuits', genderKey: ['women'] },
  { key: '5', label: '5', parentKey: 'swimsuits', genderKey: ['women'] },
  { key: '6', label: '6', parentKey: 'swimsuits', genderKey: ['women'] },
  { key: '7', label: '7', parentKey: 'swimsuits', genderKey: ['women'] },
  { key: '8', label: '8', parentKey: 'swimsuits', genderKey: ['women'] },
  { key: '9', label: '9', parentKey: 'swimsuits', genderKey: ['women'] },
  { key: '10', label: '10', parentKey: 'swimsuits', genderKey: ['women'] },
  { key: '11', label: '11', parentKey: 'swimsuits', genderKey: ['women'] },
  { key: '12', label: '12', parentKey: 'swimsuits', genderKey: ['women'] },
  { key: '13', label: '13', parentKey: 'swimsuits', genderKey: ['women'] },
  { key: '15', label: '15', parentKey: 'swimsuits', genderKey: ['women'] },
  { key: '17', label: '17', parentKey: 'swimsuits', genderKey: ['women'] },
  { key: 'XXS', label: 'XXS', parentKey: 'swimsuits', genderKey: ['women'] },
  { key: 'XS', label: 'XS', parentKey: 'swimsuits', genderKey: ['women'] },
  { key: 'S', label: 'S', parentKey: 'swimsuits', genderKey: ['women'] },
  { key: 'M', label: 'M', parentKey: 'swimsuits', genderKey: ['women'] },
  { key: 'L', label: 'L', parentKey: 'swimsuits', genderKey: ['women'] },
  { key: 'XL', label: 'XL', parentKey: 'swimsuits', genderKey: ['women'] },
  { key: 'XXL', label: 'XXL', parentKey: 'swimsuits', genderKey: ['women'] },
  { key: '00P', label: '00P', parentKey: 'swimsuits', genderKey: ['women'] },
  { key: '0P', label: '0P', parentKey: 'swimsuits', genderKey: ['women'] },
  { key: '2P', label: '2P', parentKey: 'swimsuits', genderKey: ['women'] },
  { key: '4P', label: '4P', parentKey: 'swimsuits', genderKey: ['women'] },
  { key: '6P', label: '6P', parentKey: 'swimsuits', genderKey: ['women'] },
  { key: '8P', label: '8P', parentKey: 'swimsuits', genderKey: ['women'] },
  { key: '10P', label: '10P', parentKey: 'swimsuits', genderKey: ['women'] },
  { key: '12P', label: '12P', parentKey: 'swimsuits', genderKey: ['women'] },
  { key: '14P', label: '14P', parentKey: 'swimsuits', genderKey: ['women'] },
  { key: '16P', label: '16P', parentKey: 'swimsuits', genderKey: ['women'] },
  { key: '18P', label: '18P', parentKey: 'swimsuits', genderKey: ['women'] },
  { key: '20P', label: '20P', parentKey: 'swimsuits', genderKey: ['women'] },
  { key: 'XXSP', label: 'XXSP', parentKey: 'swimsuits', genderKey: ['women'] },
  { key: 'XSP', label: 'XSP', parentKey: 'swimsuits', genderKey: ['women'] },
  { key: 'SP', label: 'SP', parentKey: 'swimsuits', genderKey: ['women'] },
  { key: 'MP', label: 'MP', parentKey: 'swimsuits', genderKey: ['women'] },
  { key: 'LP', label: 'LP', parentKey: 'swimsuits', genderKey: ['women'] },
  { key: 'XLP', label: 'XLP', parentKey: 'swimsuits', genderKey: ['women'] },
  { key: 'XXLP', label: 'XXLP', parentKey: 'swimsuits', genderKey: ['women'] },

  { key: 'one-size', label: 'One Size', parentKey: 'jackets-coats', genderKey: ['men', 'women'] },
  { key: 'custom', label: 'Custom', parentKey: 'jackets-coats', genderKey: ['men', 'women'] },
  { key: 'XXS', label: 'XXS', parentKey: 'jackets-coats', genderKey: ['women'] },
  { key: '00', label: '00', parentKey: 'jackets-coats', genderKey: ['women'] },
  { key: '0', label: '0', parentKey: 'jackets-coats', genderKey: ['women'] },
  { key: '2', label: '2', parentKey: 'jackets-coats', genderKey: ['women'] },
  { key: '4', label: '4', parentKey: 'jackets-coats', genderKey: ['women'] },
  { key: '6', label: '6', parentKey: 'jackets-coats', genderKey: ['women'] },
  { key: '8', label: '8', parentKey: 'jackets-coats', genderKey: ['women'] },
  { key: '10', label: '10', parentKey: 'jackets-coats', genderKey: ['women'] },
  { key: '12', label: '12', parentKey: 'jackets-coats', genderKey: ['women'] },
  { key: 'XXL', label: 'XXL', parentKey: 'jackets-coats', genderKey: ['women'] },
  { key: 'XXXL', label: 'XXXL', parentKey: 'jackets-coats', genderKey: ['women'] },
  { key: '0X', label: '0X', parentKey: 'jackets-coats', genderKey: ['women'] },
  { key: '1X', label: '1X', parentKey: 'jackets-coats', genderKey: ['women'] },
  { key: '2X', label: '2X', parentKey: 'jackets-coats', genderKey: ['women'] },
  { key: '3X', label: '3X', parentKey: 'jackets-coats', genderKey: ['women'] },
  { key: '4X', label: '4X', parentKey: 'jackets-coats', genderKey: ['women'] },
  { key: '5X', label: '5X', parentKey: 'jackets-coats', genderKey: ['women'] },
  { key: '14', label: '14', parentKey: 'jackets-coats', genderKey: ['women'] },
  { key: '14W', label: '14W', parentKey: 'jackets-coats', genderKey: ['women'] },
  { key: '16', label: '16', parentKey: 'jackets-coats', genderKey: ['women'] },
  { key: '16W', label: '16W', parentKey: 'jackets-coats', genderKey: ['women'] },
  { key: '18', label: '18', parentKey: 'jackets-coats', genderKey: ['women'] },
  { key: '18W', label: '18W', parentKey: 'jackets-coats', genderKey: ['women'] },
  { key: '20', label: '20', parentKey: 'jackets-coats', genderKey: ['women'] },
  { key: '20W', label: '20W', parentKey: 'jackets-coats', genderKey: ['women'] },
  { key: '22', label: '22', parentKey: 'jackets-coats', genderKey: ['women'] },
  { key: '22W', label: '22W', parentKey: 'jackets-coats', genderKey: ['women'] },
  { key: '24', label: '24', parentKey: 'jackets-coats', genderKey: ['women'] },
  { key: '24W', label: '24W', parentKey: 'jackets-coats', genderKey: ['women'] },
  { key: '26', label: '26', parentKey: 'jackets-coats', genderKey: ['women'] },
  { key: '26W', label: '26W', parentKey: 'jackets-coats', genderKey: ['women'] },
  { key: '28', label: '28', parentKey: 'jackets-coats', genderKey: ['women'] },
  { key: '28W', label: '28W', parentKey: 'jackets-coats', genderKey: ['women'] },
  { key: '30', label: '30', parentKey: 'jackets-coats', genderKey: ['women'] },
  { key: '30W', label: '30W', parentKey: 'jackets-coats', genderKey: ['women'] },
  { key: '32', label: '32', parentKey: 'jackets-coats', genderKey: ['women'] },
  { key: '32W', label: '32W', parentKey: 'jackets-coats', genderKey: ['women'] },
  { key: '00', label: '00', parentKey: 'jackets-coats', genderKey: ['women'] },
  { key: '0', label: '0', parentKey: 'jackets-coats', genderKey: ['women'] },
  { key: '1', label: '1', parentKey: 'jackets-coats', genderKey: ['women'] },
  { key: '2', label: '2', parentKey: 'jackets-coats', genderKey: ['women'] },
  { key: '3', label: '3', parentKey: 'jackets-coats', genderKey: ['women'] },
  { key: '4', label: '4', parentKey: 'jackets-coats', genderKey: ['women'] },
  { key: '5', label: '5', parentKey: 'jackets-coats', genderKey: ['women'] },
  { key: '6', label: '6', parentKey: 'jackets-coats', genderKey: ['women'] },
  { key: '7', label: '7', parentKey: 'jackets-coats', genderKey: ['women'] },
  { key: '8', label: '8', parentKey: 'jackets-coats', genderKey: ['women'] },
  { key: '9', label: '9', parentKey: 'jackets-coats', genderKey: ['women'] },
  { key: '10', label: '10', parentKey: 'jackets-coats', genderKey: ['women'] },
  { key: '11', label: '11', parentKey: 'jackets-coats', genderKey: ['women'] },
  { key: '12', label: '12', parentKey: 'jackets-coats', genderKey: ['women'] },
  { key: '13', label: '13', parentKey: 'jackets-coats', genderKey: ['women'] },
  { key: '15', label: '15', parentKey: 'jackets-coats', genderKey: ['women'] },
  { key: '17', label: '17', parentKey: 'jackets-coats', genderKey: ['women'] },
  { key: 'XXS', label: 'XXS', parentKey: 'jackets-coats', genderKey: ['women'] },
  { key: 'XS', label: 'XS', parentKey: 'jackets-coats', genderKey: ['women'] },
  { key: 'S', label: 'S', parentKey: 'jackets-coats', genderKey: ['women'] },
  { key: 'M', label: 'M', parentKey: 'jackets-coats', genderKey: ['women'] },
  { key: 'L', label: 'L', parentKey: 'jackets-coats', genderKey: ['women'] },
  { key: 'XL', label: 'XL', parentKey: 'jackets-coats', genderKey: ['women'] },
  { key: 'XXL', label: 'XXL', parentKey: 'jackets-coats', genderKey: ['women'] },
  { key: '00P', label: '00P', parentKey: 'jackets-coats', genderKey: ['women'] },
  { key: '0P', label: '0P', parentKey: 'jackets-coats', genderKey: ['women'] },
  { key: '2P', label: '2P', parentKey: 'jackets-coats', genderKey: ['women'] },
  { key: '4P', label: '4P', parentKey: 'jackets-coats', genderKey: ['women'] },
  { key: '6P', label: '6P', parentKey: 'jackets-coats', genderKey: ['women'] },
  { key: '8P', label: '8P', parentKey: 'jackets-coats', genderKey: ['women'] },
  { key: '10P', label: '10P', parentKey: 'jackets-coats', genderKey: ['women'] },
  { key: '12P', label: '12P', parentKey: 'jackets-coats', genderKey: ['women'] },
  { key: '14P', label: '14P', parentKey: 'jackets-coats', genderKey: ['women'] },
  { key: '16P', label: '16P', parentKey: 'jackets-coats', genderKey: ['women'] },
  { key: '18P', label: '18P', parentKey: 'jackets-coats', genderKey: ['women'] },
  { key: '20P', label: '20P', parentKey: 'jackets-coats', genderKey: ['women'] },
  { key: 'XXSP', label: 'XXSP', parentKey: 'jackets-coats', genderKey: ['women'] },
  { key: 'XSP', label: 'XSP', parentKey: 'jackets-coats', genderKey: ['women'] },
  { key: 'SP', label: 'SP', parentKey: 'jackets-coats', genderKey: ['women'] },
  { key: 'MP', label: 'MP', parentKey: 'jackets-coats', genderKey: ['women'] },
  { key: 'LP', label: 'LP', parentKey: 'jackets-coats', genderKey: ['women'] },
  { key: 'XLP', label: 'XLP', parentKey: 'jackets-coats', genderKey: ['women'] },
  { key: 'XXLP', label: 'XXLP', parentKey: 'jackets-coats', genderKey: ['women'] },

  { key: 'XS', label: 'XS', parentKey: 'jackets-coats', genderKey: ['men'] },
  { key: 'S', label: 'S', parentKey: 'jackets-coats', genderKey: ['men'] },
  { key: 'M', label: 'M', parentKey: 'jackets-coats', genderKey: ['men'] },
  { key: 'L', label: 'L', parentKey: 'jackets-coats', genderKey: ['men'] },
  { key: 'XL', label: 'XL', parentKey: 'jackets-coats', genderKey: ['men'] },
  { key: 'XXL', label: 'XXL', parentKey: 'jackets-coats', genderKey: ['men'] },
  { key: '3XL', label: '3XL', parentKey: 'jackets-coats', genderKey: ['men'] },

  {key: '4XL', label: '4XL', parentKey: 'jackets-coats', genderKey: ['men']},
  {key: '5XL', label: '5XL', parentKey: 'jackets-coats', genderKey: ['men']},
  {key: '6XL', label: '6XL', parentKey: 'jackets-coats', genderKey: ['men']},
  {key: 'LT', label: 'LT', parentKey: 'jackets-coats', genderKey: ['men']},
  {key: 'XLT', label: 'XLT', parentKey: 'jackets-coats', genderKey: ['men']},
  {key: '2XLT', label: '2XLT', parentKey: 'jackets-coats', genderKey: ['men']},
  {key: '3XLT', label: '3XLT', parentKey: 'jackets-coats', genderKey: ['men']},
  {key: '4XLT', label: '4XLT', parentKey: 'jackets-coats', genderKey: ['men']},
  {key: '5XLT', label: '5XLT', parentKey: 'jackets-coats', genderKey: ['men']},
  {key: '6XLT', label: '6XLT', parentKey: 'jackets-coats', genderKey: ['men']},
  
  // { key: '14.5', label: '14.5', parentKey: 'jackets-coats', genderKey: ['men'] },
  // { key: '15', label: '15', parentKey: 'jackets-coats', genderKey: ['men'] },
  // { key: '15.5', label: '15.5', parentKey: 'jackets-coats', genderKey: ['men'] },
  // { key: '16', label: '16', parentKey: 'jackets-coats', genderKey: ['men'] },
  // { key: '16.5', label: '16.5', parentKey: 'jackets-coats', genderKey: ['men'] },
  // { key: '17', label: '17', parentKey: 'jackets-coats', genderKey: ['men'] },
  // { key: '17.5', label: '17.5', parentKey: 'jackets-coats', genderKey: ['men'] },
  // { key: '18', label: '18', parentKey: 'jackets-coats', genderKey: ['men'] },
  // { key: '18.5', label: '18.5', parentKey: 'jackets-coats', genderKey: ['men'] },
  // { key: '19', label: '19', parentKey: 'jackets-coats', genderKey: ['men'] },
  // { key: '19.5', label: '19.5', parentKey: 'jackets-coats', genderKey: ['men'] },
  // { key: '20', label: '20', parentKey: 'jackets-coats', genderKey: ['men'] },

  { key: 'one-size', label: 'One Size', parentKey: 'jeans', genderKey: ['men', 'women'] },
  { key: 'custom', label: 'Custom', parentKey: 'jeans', genderKey: ['men', 'women'] },
  { key: 'XS', label: 'XS', parentKey: 'jeans', genderKey: ['men'] },
  { key: 'S', label: 'S', parentKey: 'jeans', genderKey: ['men'] },
  { key: 'M', label: 'M', parentKey: 'jeans', genderKey: ['men'] },
  { key: 'L', label: 'L', parentKey: 'jeans', genderKey: ['men'] },
  { key: 'XL', label: 'XL', parentKey: 'jeans', genderKey: ['men'] },
  { key: 'XXL', label: 'XXL', parentKey: 'jeans', genderKey: ['men'] },
  { key: '3XL', label: '3XL', parentKey: 'jeans', genderKey: ['men'] },
  { key: '28', label: '28', parentKey: 'jeans', genderKey: ['men'] },
  { key: '29', label: '29', parentKey: 'jeans', genderKey: ['men'] },
  { key: '30', label: '30', parentKey: 'jeans', genderKey: ['men'] },
  { key: '31', label: '31', parentKey: 'jeans', genderKey: ['men'] },
  { key: '32', label: '32', parentKey: 'jeans', genderKey: ['men'] },
  { key: '33', label: '33', parentKey: 'jeans', genderKey: ['men'] },
  { key: '34', label: '34', parentKey: 'jeans', genderKey: ['men'] },
  { key: '35', label: '35', parentKey: 'jeans', genderKey: ['men'] },
  { key: '36', label: '36', parentKey: 'jeans', genderKey: ['men'] },
  { key: '37', label: '37', parentKey: 'jeans', genderKey: ['men'] },
  { key: '38', label: '38', parentKey: 'jeans', genderKey: ['men'] },
  { key: '39', label: '39', parentKey: 'jeans', genderKey: ['men'] },
  { key: '40', label: '40', parentKey: 'jeans', genderKey: ['men'] },
  { key: '41', label: '41', parentKey: 'jeans', genderKey: ['men'] },
  { key: '42', label: '42', parentKey: 'jeans', genderKey: ['men'] },
  { key: '43', label: '43', parentKey: 'jeans', genderKey: ['men'] },
  { key: '44', label: '44', parentKey: 'jeans', genderKey: ['men'] },
  { key: '46', label: '46', parentKey: 'jeans', genderKey: ['men'] },
  { key: '48', label: '48', parentKey: 'jeans', genderKey: ['men'] },
  { key: '50', label: '50', parentKey: 'jeans', genderKey: ['men'] },
  { key: '52', label: '52', parentKey: 'jeans', genderKey: ['men'] },
  { key: '54', label: '54', parentKey: 'jeans', genderKey: ['men'] },
  { key: '56', label: '56', parentKey: 'jeans', genderKey: ['men'] },
  { key: '58', label: '58', parentKey: 'jeans', genderKey: ['men'] },
  { key: '60', label: '60', parentKey: 'jeans', genderKey: ['men'] },

  { key: 'XXS', label: 'XXS', parentKey: 'jeans', genderKey: ['women'] },
  { key: 'XS', label: 'XS', parentKey: 'jeans', genderKey: ['women'] },
  { key: 'S', label: 'S', parentKey: 'jeans', genderKey: ['women'] },
  { key: 'M', label: 'M', parentKey: 'jeans', genderKey: ['women'] },
  { key: 'L', label: 'L', parentKey: 'jeans', genderKey: ['women'] },
  { key: 'XL', label: 'XL', parentKey: 'jeans', genderKey: ['women'] },
  { key: '23', label: '23', parentKey: 'jeans', genderKey: ['women'] },
  { key: '24', label: '24', parentKey: 'jeans', genderKey: ['women'] },
  { key: '25', label: '25', parentKey: 'jeans', genderKey: ['women'] },
  { key: '26', label: '26', parentKey: 'jeans', genderKey: ['women'] },
  { key: '27', label: '27', parentKey: 'jeans', genderKey: ['women'] },
  { key: '28', label: '28', parentKey: 'jeans', genderKey: ['women'] },
  { key: '29', label: '29', parentKey: 'jeans', genderKey: ['women'] },
  { key: '30', label: '30', parentKey: 'jeans', genderKey: ['women'] },
  { key: '31', label: '31', parentKey: 'jeans', genderKey: ['women'] },
  { key: '00', label: '00', parentKey: 'jeans', genderKey: ['women'] },
  { key: '0', label: '0', parentKey: 'jeans', genderKey: ['women'] },
  { key: '2', label: '2', parentKey: 'jeans', genderKey: ['women'] },
  { key: '4', label: '4', parentKey: 'jeans', genderKey: ['women'] },
  { key: '6', label: '6', parentKey: 'jeans', genderKey: ['women'] },
  { key: '8', label: '8', parentKey: 'jeans', genderKey: ['women'] },
  { key: '10', label: '10', parentKey: 'jeans', genderKey: ['women'] },
  { key: '12', label: '12', parentKey: 'jeans', genderKey: ['women'] },

  { key: '32', label: '32', parentKey: 'jeans', genderKey: ['women'] },
  { key: '33', label: '33', parentKey: 'jeans', genderKey: ['women'] },
  { key: '34', label: '34', parentKey: 'jeans', genderKey: ['women'] },
  { key: 'XXL', label: 'XXL', parentKey: 'jeans', genderKey: ['women'] },
  { key: 'XXXL', label: 'XXXL', parentKey: 'jeans', genderKey: ['women'] },
  { key: '0X', label: '0X', parentKey: 'jeans', genderKey: ['women'] },
  { key: '1X', label: '1X', parentKey: 'jeans', genderKey: ['women'] },
  { key: '2X', label: '2X', parentKey: 'jeans', genderKey: ['women'] },
  { key: '3X', label: '3X', parentKey: 'jeans', genderKey: ['women'] },
  { key: '4X', label: '4X', parentKey: 'jeans', genderKey: ['women'] },
  { key: '5X', label: '5X', parentKey: 'jeans', genderKey: ['women'] },
  { key: '14', label: '14', parentKey: 'jeans', genderKey: ['women'] },
  { key: '14W', label: '14W', parentKey: 'jeans', genderKey: ['women'] },
  { key: '16', label: '16', parentKey: 'jeans', genderKey: ['women'] },
  { key: '16W', label: '16W', parentKey: 'jeans', genderKey: ['women'] },
  { key: '18', label: '18', parentKey: 'jeans', genderKey: ['women'] },
  { key: '18W', label: '18W', parentKey: 'jeans', genderKey: ['women'] },
  { key: '20', label: '20', parentKey: 'jeans', genderKey: ['women'] },
  { key: '20W', label: '20W', parentKey: 'jeans', genderKey: ['women'] },
  { key: '22', label: '22', parentKey: 'jeans', genderKey: ['women'] },
  { key: '22W', label: '22W', parentKey: 'jeans', genderKey: ['women'] },
  { key: '24', label: '24', parentKey: 'jeans', genderKey: ['women'] },
  { key: '24W', label: '24W', parentKey: 'jeans', genderKey: ['women'] },
  { key: '26', label: '26', parentKey: 'jeans', genderKey: ['women'] },
  { key: '26W', label: '26W', parentKey: 'jeans', genderKey: ['women'] },
  { key: '28', label: '28', parentKey: 'jeans', genderKey: ['women'] },
  { key: '28W', label: '28W', parentKey: 'jeans', genderKey: ['women'] },
  { key: '30', label: '30', parentKey: 'jeans', genderKey: ['women'] },
  { key: '30W', label: '30W', parentKey: 'jeans', genderKey: ['women'] },
  { key: '32', label: '32', parentKey: 'jeans', genderKey: ['women'] },
  { key: '32W', label: '32W', parentKey: 'jeans', genderKey: ['women'] },
  { key: '23P', label: '23P', parentKey: 'jeans', genderKey: ['women'] },
  { key: '24P', label: '24P', parentKey: 'jeans', genderKey: ['women'] },
  { key: '25P', label: '25P', parentKey: 'jeans', genderKey: ['women'] },
  { key: '26P', label: '26P', parentKey: 'jeans', genderKey: ['women'] },
  { key: '27P', label: '27P', parentKey: 'jeans', genderKey: ['women'] },
  { key: '28P', label: '28P', parentKey: 'jeans', genderKey: ['women'] },
  { key: '29P', label: '29P', parentKey: 'jeans', genderKey: ['women'] },
  { key: '30P', label: '30P', parentKey: 'jeans', genderKey: ['women'] },
  { key: '31P', label: '31P', parentKey: 'jeans', genderKey: ['women'] },
  { key: '32P', label: '32P', parentKey: 'jeans', genderKey: ['women'] },
  { key: '33P', label: '33P', parentKey: 'jeans', genderKey: ['women'] },
  { key: '34P', label: '34P', parentKey: 'jeans', genderKey: ['women'] },
  { key: '00P', label: '00P', parentKey: 'jeans', genderKey: ['women'] },
  { key: '0P', label: '0P', parentKey: 'jeans', genderKey: ['women'] },
  { key: '2P', label: '2P', parentKey: 'jeans', genderKey: ['women'] },
  { key: '4P', label: '4P', parentKey: 'jeans', genderKey: ['women'] },
  { key: '6P', label: '6P', parentKey: 'jeans', genderKey: ['women'] },
  { key: '8P', label: '8P', parentKey: 'jeans', genderKey: ['women'] },
  { key: '10P', label: '10P', parentKey: 'jeans', genderKey: ['women'] },
  { key: '12P', label: '12P', parentKey: 'jeans', genderKey: ['women'] },
  { key: '14P', label: '14P', parentKey: 'jeans', genderKey: ['women'] },
  { key: '16P', label: '16P', parentKey: 'jeans', genderKey: ['women'] },
  { key: '18P', label: '18P', parentKey: 'jeans', genderKey: ['women'] },
  { key: '20P', label: '20P', parentKey: 'jeans', genderKey: ['women'] },
  { key: 'XXSP', label: 'XXSP', parentKey: 'jeans', genderKey: ['women'] },
  { key: 'XSP', label: 'XSP', parentKey: 'jeans', genderKey: ['women'] },
  { key: 'SP', label: 'SP', parentKey: 'jeans', genderKey: ['women'] },
  { key: 'MP', label: 'MP', parentKey: 'jeans', genderKey: ['women'] },
  { key: 'LP', label: 'LP', parentKey: 'jeans', genderKey: ['women'] },
  { key: 'XLP', label: 'XLP', parentKey: 'jeans', genderKey: ['women'] },
  { key: 'XXLP', label: 'XXLP', parentKey: 'jeans', genderKey: ['women'] },

  { key: 'one-size', label: 'One Size', parentKey: 'pants', genderKey: ['men', 'women'] },
  { key: 'custom', label: 'Custom', parentKey: 'pants', genderKey: ['men', 'women'] },
  { key: 'XS', label: 'XS', parentKey: 'pants', genderKey: ['men'] },
  { key: 'S', label: 'S', parentKey: 'pants', genderKey: ['men'] },
  { key: 'M', label: 'M', parentKey: 'pants', genderKey: ['men'] },
  { key: 'L', label: 'L', parentKey: 'pants', genderKey: ['men'] },
  { key: 'XL', label: 'XL', parentKey: 'pants', genderKey: ['men'] },
  { key: 'XXL', label: 'XXL', parentKey: 'pants', genderKey: ['men'] },
  { key: '3XL', label: '3XL', parentKey: 'pants', genderKey: ['men'] },
  { key: '28', label: '28', parentKey: 'pants', genderKey: ['men'] },
  { key: '29', label: '29', parentKey: 'pants', genderKey: ['men'] },
  { key: '30', label: '30', parentKey: 'pants', genderKey: ['men'] },
  { key: '31', label: '31', parentKey: 'pants', genderKey: ['men'] },
  { key: '32', label: '32', parentKey: 'pants', genderKey: ['men'] },
  { key: '33', label: '33', parentKey: 'pants', genderKey: ['men'] },
  { key: '34', label: '34', parentKey: 'pants', genderKey: ['men'] },
  { key: '35', label: '35', parentKey: 'pants', genderKey: ['men'] },
  { key: '36', label: '36', parentKey: 'pants', genderKey: ['men'] },
  { key: '37', label: '37', parentKey: 'pants', genderKey: ['men'] },
  { key: '38', label: '38', parentKey: 'pants', genderKey: ['men'] },
  { key: '39', label: '39', parentKey: 'pants', genderKey: ['men'] },
  { key: '40', label: '40', parentKey: 'pants', genderKey: ['men'] },
  { key: '41', label: '41', parentKey: 'pants', genderKey: ['men'] },
  { key: '42', label: '42', parentKey: 'pants', genderKey: ['men'] },
  { key: '43', label: '43', parentKey: 'pants', genderKey: ['men'] },
  { key: '44', label: '44', parentKey: 'pants', genderKey: ['men'] },
  { key: '46', label: '46', parentKey: 'pants', genderKey: ['men'] },
  { key: '48', label: '48', parentKey: 'pants', genderKey: ['men'] },
  { key: '50', label: '50', parentKey: 'pants', genderKey: ['men'] },
  { key: '52', label: '52', parentKey: 'pants', genderKey: ['men'] },
  { key: '54', label: '54', parentKey: 'pants', genderKey: ['men'] },
  { key: '56', label: '56', parentKey: 'pants', genderKey: ['men'] },
  { key: '58', label: '58', parentKey: 'pants', genderKey: ['men'] },
  { key: '60', label: '60', parentKey: 'pants', genderKey: ['men'] },

  { key: 'XXS', label: 'XXS', parentKey: 'pants', genderKey: ['women'] },
  { key: 'XS', label: 'XS', parentKey: 'pants', genderKey: ['women'] },
  { key: 'S', label: 'S', parentKey: 'pants', genderKey: ['women'] },
  { key: 'M', label: 'M', parentKey: 'pants', genderKey: ['women'] },
  { key: 'L', label: 'L', parentKey: 'pants', genderKey: ['women'] },
  { key: 'XL', label: 'XL', parentKey: 'pants', genderKey: ['women'] },
  { key: '23', label: '23', parentKey: 'pants', genderKey: ['women'] },
  { key: '24', label: '24', parentKey: 'pants', genderKey: ['women'] },
  { key: '25', label: '25', parentKey: 'pants', genderKey: ['women'] },
  { key: '26', label: '26', parentKey: 'pants', genderKey: ['women'] },
  { key: '27', label: '27', parentKey: 'pants', genderKey: ['women'] },
  { key: '28', label: '28', parentKey: 'pants', genderKey: ['women'] },
  { key: '29', label: '29', parentKey: 'pants', genderKey: ['women'] },
  { key: '30', label: '30', parentKey: 'pants', genderKey: ['women'] },
  { key: '31', label: '31', parentKey: 'pants', genderKey: ['women'] },
  { key: '00', label: '00', parentKey: 'pants', genderKey: ['women'] },
  { key: '0', label: '0', parentKey: 'pants', genderKey: ['women'] },
  { key: '2', label: '2', parentKey: 'pants', genderKey: ['women'] },
  { key: '4', label: '4', parentKey: 'pants', genderKey: ['women'] },
  { key: '6', label: '6', parentKey: 'pants', genderKey: ['women'] },
  { key: '8', label: '8', parentKey: 'pants', genderKey: ['women'] },
  { key: '10', label: '10', parentKey: 'pants', genderKey: ['women'] },
  { key: '12', label: '12', parentKey: 'pants', genderKey: ['women'] },
  { key: '32', label: '32', parentKey: 'pants', genderKey: ['women'] },
  { key: '33', label: '33', parentKey: 'pants', genderKey: ['women'] },
  { key: '34', label: '34', parentKey: 'pants', genderKey: ['women'] },
  { key: 'XXL', label: 'XXL', parentKey: 'pants', genderKey: ['women'] },
  { key: 'XXXL', label: 'XXXL', parentKey: 'pants', genderKey: ['women'] },
  { key: '0X', label: '0X', parentKey: 'pants', genderKey: ['women'] },
  { key: '1X', label: '1X', parentKey: 'pants', genderKey: ['women'] },
  { key: '2X', label: '2X', parentKey: 'pants', genderKey: ['women'] },
  { key: '3X', label: '3X', parentKey: 'pants', genderKey: ['women'] },
  { key: '4X', label: '4X', parentKey: 'pants', genderKey: ['women'] },
  { key: '5X', label: '5X', parentKey: 'pants', genderKey: ['women'] },
  { key: '14', label: '14', parentKey: 'pants', genderKey: ['women'] },
  { key: '14W', label: '14W', parentKey: 'pants', genderKey: ['women'] },
  { key: '16', label: '16', parentKey: 'pants', genderKey: ['women'] },
  { key: '16W', label: '16W', parentKey: 'pants', genderKey: ['women'] },
  { key: '18', label: '18', parentKey: 'pants', genderKey: ['women'] },
  { key: '18W', label: '18W', parentKey: 'pants', genderKey: ['women'] },
  { key: '20', label: '20', parentKey: 'pants', genderKey: ['women'] },
  { key: '20W', label: '20W', parentKey: 'pants', genderKey: ['women'] },
  { key: '22', label: '22', parentKey: 'pants', genderKey: ['women'] },
  { key: '22W', label: '22W', parentKey: 'pants', genderKey: ['women'] },
  { key: '24', label: '24', parentKey: 'pants', genderKey: ['women'] },
  { key: '24W', label: '24W', parentKey: 'pants', genderKey: ['women'] },
  { key: '26', label: '26', parentKey: 'pants', genderKey: ['women'] },
  { key: '26W', label: '26W', parentKey: 'pants', genderKey: ['women'] },
  { key: '28', label: '28', parentKey: 'pants', genderKey: ['women'] },
  { key: '28W', label: '28W', parentKey: 'pants', genderKey: ['women'] },
  { key: '30', label: '30', parentKey: 'pants', genderKey: ['women'] },
  { key: '30W', label: '30W', parentKey: 'pants', genderKey: ['women'] },
  { key: '32', label: '32', parentKey: 'pants', genderKey: ['women'] },
  { key: '32W', label: '32W', parentKey: 'pants', genderKey: ['women'] },
  { key: '23P', label: '23P', parentKey: 'pants', genderKey: ['women'] },
  { key: '24P', label: '24P', parentKey: 'pants', genderKey: ['women'] },
  { key: '25P', label: '25P', parentKey: 'pants', genderKey: ['women'] },
  { key: '26P', label: '26P', parentKey: 'pants', genderKey: ['women'] },
  { key: '27P', label: '27P', parentKey: 'pants', genderKey: ['women'] },
  { key: '28P', label: '28P', parentKey: 'pants', genderKey: ['women'] },
  { key: '29P', label: '29P', parentKey: 'pants', genderKey: ['women'] },
  { key: '30P', label: '30P', parentKey: 'pants', genderKey: ['women'] },
  { key: '31P', label: '31P', parentKey: 'pants', genderKey: ['women'] },
  { key: '32P', label: '32P', parentKey: 'pants', genderKey: ['women'] },
  { key: '33P', label: '33P', parentKey: 'pants', genderKey: ['women'] },
  { key: '34P', label: '34P', parentKey: 'pants', genderKey: ['women'] },
  { key: '00P', label: '00P', parentKey: 'pants', genderKey: ['women'] },
  { key: '0P', label: '0P', parentKey: 'pants', genderKey: ['women'] },
  { key: '2P', label: '2P', parentKey: 'pants', genderKey: ['women'] },
  { key: '4P', label: '4P', parentKey: 'pants', genderKey: ['women'] },
  { key: '6P', label: '6P', parentKey: 'pants', genderKey: ['women'] },
  { key: '8P', label: '8P', parentKey: 'pants', genderKey: ['women'] },
  { key: '10P', label: '10P', parentKey: 'pants', genderKey: ['women'] },
  { key: '12P', label: '12P', parentKey: 'pants', genderKey: ['women'] },
  { key: '14P', label: '14P', parentKey: 'pants', genderKey: ['women'] },
  { key: '16P', label: '16P', parentKey: 'pants', genderKey: ['women'] },
  { key: '18P', label: '18P', parentKey: 'pants', genderKey: ['women'] },
  { key: '20P', label: '20P', parentKey: 'pants', genderKey: ['women'] },
  { key: 'XXSP', label: 'XXSP', parentKey: 'pants', genderKey: ['women'] },
  { key: 'XSP', label: 'XSP', parentKey: 'pants', genderKey: ['women'] },
  { key: 'SP', label: 'SP', parentKey: 'pants', genderKey: ['women'] },
  { key: 'MP', label: 'MP', parentKey: 'pants', genderKey: ['women'] },
  { key: 'LP', label: 'LP', parentKey: 'pants', genderKey: ['women'] },
  { key: 'XLP', label: 'XLP', parentKey: 'pants', genderKey: ['women'] },
  { key: 'XXLP', label: 'XXLP', parentKey: 'pants', genderKey: ['women'] },

  { key: 'one-size', label: 'One Size', parentKey: 'shorts', genderKey: ['men', 'women'] },
  { key: 'custom', label: 'Custom', parentKey: 'shorts', genderKey: ['men', 'women'] },
  { key: 'XS', label: 'XS', parentKey: 'shorts', genderKey: ['men'] },
  { key: 'S', label: 'S', parentKey: 'shorts', genderKey: ['men'] },
  { key: 'M', label: 'M', parentKey: 'shorts', genderKey: ['men'] },
  { key: 'L', label: 'L', parentKey: 'shorts', genderKey: ['men'] },
  { key: 'XL', label: 'XL', parentKey: 'shorts', genderKey: ['men'] },
  { key: 'XXL', label: 'XXL', parentKey: 'shorts', genderKey: ['men'] },
  { key: '3XL', label: '3XL', parentKey: 'shorts', genderKey: ['men'] },
  { key: '28', label: '28', parentKey: 'shorts', genderKey: ['men'] },
  { key: '29', label: '29', parentKey: 'shorts', genderKey: ['men'] },
  { key: '30', label: '30', parentKey: 'shorts', genderKey: ['men'] },
  { key: '31', label: '31', parentKey: 'shorts', genderKey: ['men'] },
  { key: '32', label: '32', parentKey: 'shorts', genderKey: ['men'] },
  { key: '33', label: '33', parentKey: 'shorts', genderKey: ['men'] },
  { key: '34', label: '34', parentKey: 'shorts', genderKey: ['men'] },
  { key: '35', label: '35', parentKey: 'shorts', genderKey: ['men'] },
  { key: '36', label: '36', parentKey: 'shorts', genderKey: ['men'] },
  { key: '37', label: '37', parentKey: 'shorts', genderKey: ['men'] },
  { key: '38', label: '38', parentKey: 'shorts', genderKey: ['men'] },
  { key: '39', label: '39', parentKey: 'shorts', genderKey: ['men'] },
  { key: '40', label: '40', parentKey: 'shorts', genderKey: ['men'] },
  { key: '41', label: '41', parentKey: 'shorts', genderKey: ['men'] },
  { key: '42', label: '42', parentKey: 'shorts', genderKey: ['men'] },
  { key: '43', label: '43', parentKey: 'shorts', genderKey: ['men'] },
  { key: '44', label: '44', parentKey: 'shorts', genderKey: ['men'] },
  { key: '46', label: '46', parentKey: 'shorts', genderKey: ['men'] },
  { key: '48', label: '48', parentKey: 'shorts', genderKey: ['men'] },
  { key: '50', label: '50', parentKey: 'shorts', genderKey: ['men'] },
  { key: '52', label: '52', parentKey: 'shorts', genderKey: ['men'] },
  { key: '54', label: '54', parentKey: 'shorts', genderKey: ['men'] },
  { key: '56', label: '56', parentKey: 'shorts', genderKey: ['men'] },
  { key: '58', label: '58', parentKey: 'shorts', genderKey: ['men'] },
  { key: '60', label: '60', parentKey: 'shorts', genderKey: ['men'] },

  { key: 'XXS', label: 'XXS', parentKey: 'shorts', genderKey: ['women'] },
  { key: 'XS', label: 'XS', parentKey: 'shorts', genderKey: ['women'] },
  { key: 'S', label: 'S', parentKey: 'shorts', genderKey: ['women'] },
  { key: 'M', label: 'M', parentKey: 'shorts', genderKey: ['women'] },
  { key: 'L', label: 'L', parentKey: 'shorts', genderKey: ['women'] },
  { key: 'XL', label: 'XL', parentKey: 'shorts', genderKey: ['women'] },
  { key: '23', label: '23', parentKey: 'shorts', genderKey: ['women'] },
  { key: '24', label: '24', parentKey: 'shorts', genderKey: ['women'] },
  { key: '25', label: '25', parentKey: 'shorts', genderKey: ['women'] },
  { key: '26', label: '26', parentKey: 'shorts', genderKey: ['women'] },
  { key: '27', label: '27', parentKey: 'shorts', genderKey: ['women'] },
  { key: '28', label: '28', parentKey: 'shorts', genderKey: ['women'] },
  { key: '29', label: '29', parentKey: 'shorts', genderKey: ['women'] },
  { key: '30', label: '30', parentKey: 'shorts', genderKey: ['women'] },
  { key: '31', label: '31', parentKey: 'shorts', genderKey: ['women'] },
  { key: '00', label: '00', parentKey: 'shorts', genderKey: ['women'] },
  { key: '0', label: '0', parentKey: 'shorts', genderKey: ['women'] },
  { key: '2', label: '2', parentKey: 'shorts', genderKey: ['women'] },
  { key: '4', label: '4', parentKey: 'shorts', genderKey: ['women'] },
  { key: '6', label: '6', parentKey: 'shorts', genderKey: ['women'] },
  { key: '8', label: '8', parentKey: 'shorts', genderKey: ['women'] },
  { key: '10', label: '10', parentKey: 'shorts', genderKey: ['women'] },
  { key: '12', label: '12', parentKey: 'shorts', genderKey: ['women'] },
  { key: '32', label: '32', parentKey: 'shorts', genderKey: ['women'] },
  { key: '33', label: '33', parentKey: 'shorts', genderKey: ['women'] },
  { key: '34', label: '34', parentKey: 'shorts', genderKey: ['women'] },
  { key: 'XXL', label: 'XXL', parentKey: 'shorts', genderKey: ['women'] },
  { key: 'XXXL', label: 'XXXL', parentKey: 'shorts', genderKey: ['women'] },
  { key: '0X', label: '0X', parentKey: 'shorts', genderKey: ['women'] },
  { key: '1X', label: '1X', parentKey: 'shorts', genderKey: ['women'] },
  { key: '2X', label: '2X', parentKey: 'shorts', genderKey: ['women'] },
  { key: '3X', label: '3X', parentKey: 'shorts', genderKey: ['women'] },
  { key: '4X', label: '4X', parentKey: 'shorts', genderKey: ['women'] },
  { key: '5X', label: '5X', parentKey: 'shorts', genderKey: ['women'] },
  { key: '14', label: '14', parentKey: 'shorts', genderKey: ['women'] },
  { key: '14W', label: '14W', parentKey: 'shorts', genderKey: ['women'] },
  { key: '16', label: '16', parentKey: 'shorts', genderKey: ['women'] },
  { key: '16W', label: '16W', parentKey: 'shorts', genderKey: ['women'] },
  { key: '18', label: '18', parentKey: 'shorts', genderKey: ['women'] },
  { key: '18W', label: '18W', parentKey: 'shorts', genderKey: ['women'] },
  { key: '20', label: '20', parentKey: 'shorts', genderKey: ['women'] },
  { key: '20W', label: '20W', parentKey: 'shorts', genderKey: ['women'] },
  { key: '22', label: '22', parentKey: 'shorts', genderKey: ['women'] },
  { key: '22W', label: '22W', parentKey: 'shorts', genderKey: ['women'] },
  { key: '24', label: '24', parentKey: 'shorts', genderKey: ['women'] },
  { key: '24W', label: '24W', parentKey: 'shorts', genderKey: ['women'] },
  { key: '26', label: '26', parentKey: 'shorts', genderKey: ['women'] },
  { key: '26W', label: '26W', parentKey: 'shorts', genderKey: ['women'] },
  { key: '28', label: '28', parentKey: 'shorts', genderKey: ['women'] },
  { key: '28W', label: '28W', parentKey: 'shorts', genderKey: ['women'] },
  { key: '30', label: '30', parentKey: 'shorts', genderKey: ['women'] },
  { key: '30W', label: '30W', parentKey: 'shorts', genderKey: ['women'] },
  { key: '32', label: '32', parentKey: 'shorts', genderKey: ['women'] },
  { key: '32W', label: '32W', parentKey: 'shorts', genderKey: ['women'] },
  { key: '23P', label: '23P', parentKey: 'shorts', genderKey: ['women'] },
  { key: '24P', label: '24P', parentKey: 'shorts', genderKey: ['women'] },
  { key: '25P', label: '25P', parentKey: 'shorts', genderKey: ['women'] },
  { key: '26P', label: '26P', parentKey: 'shorts', genderKey: ['women'] },
  { key: '27P', label: '27P', parentKey: 'shorts', genderKey: ['women'] },
  { key: '28P', label: '28P', parentKey: 'shorts', genderKey: ['women'] },
  { key: '29P', label: '29P', parentKey: 'shorts', genderKey: ['women'] },
  { key: '30P', label: '30P', parentKey: 'shorts', genderKey: ['women'] },
  { key: '31P', label: '31P', parentKey: 'shorts', genderKey: ['women'] },
  { key: '32P', label: '32P', parentKey: 'shorts', genderKey: ['women'] },
  { key: '33P', label: '33P', parentKey: 'shorts', genderKey: ['women'] },
  { key: '34P', label: '34P', parentKey: 'shorts', genderKey: ['women'] },
  { key: '00P', label: '00P', parentKey: 'shorts', genderKey: ['women'] },
  { key: '0P', label: '0P', parentKey: 'shorts', genderKey: ['women'] },
  { key: '2P', label: '2P', parentKey: 'shorts', genderKey: ['women'] },
  { key: '4P', label: '4P', parentKey: 'shorts', genderKey: ['women'] },
  { key: '6P', label: '6P', parentKey: 'shorts', genderKey: ['women'] },
  { key: '8P', label: '8P', parentKey: 'shorts', genderKey: ['women'] },
  { key: '10P', label: '10P', parentKey: 'shorts', genderKey: ['women'] },
  { key: '12P', label: '12P', parentKey: 'shorts', genderKey: ['women'] },
  { key: '14P', label: '14P', parentKey: 'shorts', genderKey: ['women'] },
  { key: '16P', label: '16P', parentKey: 'shorts', genderKey: ['women'] },
  { key: '18P', label: '18P', parentKey: 'shorts', genderKey: ['women'] },
  { key: '20P', label: '20P', parentKey: 'shorts', genderKey: ['women'] },
  { key: 'XXSP', label: 'XXSP', parentKey: 'shorts', genderKey: ['women'] },
  { key: 'XSP', label: 'XSP', parentKey: 'shorts', genderKey: ['women'] },
  { key: 'SP', label: 'SP', parentKey: 'shorts', genderKey: ['women'] },
  { key: 'MP', label: 'MP', parentKey: 'shorts', genderKey: ['women'] },
  { key: 'LP', label: 'LP', parentKey: 'shorts', genderKey: ['women'] },
  { key: 'XLP', label: 'XLP', parentKey: 'shorts', genderKey: ['women'] },
  { key: 'XXLP', label: 'XXLP', parentKey: 'shorts', genderKey: ['women'] },

  { key: 'one-size', label: 'One Size', parentKey: 'suits-separates', genderKey: ['women'] },
  { key: 'custom', label: 'Custom', parentKey: 'suits-separates', genderKey: ['women'] },
  { key: 'XXS', label: 'XXS', parentKey: 'suits-separates', genderKey: ['women'] },
  { key: 'XS', label: 'XS', parentKey: 'suits-separates', genderKey: ['women'] },
  { key: 'S', label: 'S', parentKey: 'suits-separates', genderKey: ['women'] },
  { key: 'M', label: 'M', parentKey: 'suits-separates', genderKey: ['women'] },
  { key: 'L', label: 'L', parentKey: 'suits-separates', genderKey: ['women'] },
  { key: 'XL', label: 'XL', parentKey: 'suits-separates', genderKey: ['women'] },
  { key: '00', label: '00', parentKey: 'suits-separates', genderKey: ['women'] },
  { key: '0', label: '0', parentKey: 'suits-separates', genderKey: ['women'] },
  { key: '2', label: '2', parentKey: 'suits-separates', genderKey: ['women'] },
  { key: '4', label: '4', parentKey: 'suits-separates', genderKey: ['women'] },
  { key: '6', label: '6', parentKey: 'suits-separates', genderKey: ['women'] },
  { key: '8', label: '8', parentKey: 'suits-separates', genderKey: ['women'] },
  { key: '10', label: '10', parentKey: 'suits-separates', genderKey: ['women'] },
  { key: '12', label: '12', parentKey: 'suits-separates', genderKey: ['women'] },
  { key: 'XXL', label: 'XXL', parentKey: 'suits-separates', genderKey: ['women'] },
  { key: 'XXXL', label: 'XXXL', parentKey: 'suits-separates', genderKey: ['women'] },
  { key: '0X', label: '0X', parentKey: 'suits-separates', genderKey: ['women'] },
  { key: '1X', label: '1X', parentKey: 'suits-separates', genderKey: ['women'] },
  { key: '2X', label: '2X', parentKey: 'suits-separates', genderKey: ['women'] },
  { key: '3X', label: '3X', parentKey: 'suits-separates', genderKey: ['women'] },
  { key: '4X', label: '4X', parentKey: 'suits-separates', genderKey: ['women'] },
  { key: '5X', label: '5X', parentKey: 'suits-separates', genderKey: ['women'] },
  { key: '14', label: '14', parentKey: 'suits-separates', genderKey: ['women'] },
  { key: '14W', label: '14W', parentKey: 'suits-separates', genderKey: ['women'] },
  { key: '16', label: '16', parentKey: 'suits-separates', genderKey: ['women'] },
  { key: '16W', label: '16W', parentKey: 'suits-separates', genderKey: ['women'] },
  { key: '18', label: '18', parentKey: 'suits-separates', genderKey: ['women'] },
  { key: '18W', label: '18W', parentKey: 'suits-separates', genderKey: ['women'] },
  { key: '20', label: '20', parentKey: 'suits-separates', genderKey: ['women'] },
  { key: '20W', label: '20W', parentKey: 'suits-separates', genderKey: ['women'] },
  { key: '22', label: '22', parentKey: 'suits-separates', genderKey: ['women'] },
  { key: '22W', label: '22W', parentKey: 'suits-separates', genderKey: ['women'] },
  { key: '24', label: '24', parentKey: 'suits-separates', genderKey: ['women'] },
  { key: '24W', label: '24W', parentKey: 'suits-separates', genderKey: ['women'] },
  { key: '26', label: '26', parentKey: 'suits-separates', genderKey: ['women'] },
  { key: '26W', label: '26W', parentKey: 'suits-separates', genderKey: ['women'] },
  { key: '28', label: '28', parentKey: 'suits-separates', genderKey: ['women'] },
  { key: '28W', label: '28W', parentKey: 'suits-separates', genderKey: ['women'] },
  { key: '30', label: '30', parentKey: 'suits-separates', genderKey: ['women'] },
  { key: '30W', label: '30W', parentKey: 'suits-separates', genderKey: ['women'] },
  { key: '32', label: '32', parentKey: 'suits-separates', genderKey: ['women'] },
  { key: '32W', label: '32W', parentKey: 'suits-separates', genderKey: ['women'] },
  { key: '00', label: '00', parentKey: 'suits-separates', genderKey: ['women'] },
  { key: '0', label: '0', parentKey: 'suits-separates', genderKey: ['women'] },
  { key: '1', label: '1', parentKey: 'suits-separates', genderKey: ['women'] },
  { key: '2', label: '2', parentKey: 'suits-separates', genderKey: ['women'] },
  { key: '3', label: '3', parentKey: 'suits-separates', genderKey: ['women'] },
  { key: '4', label: '4', parentKey: 'suits-separates', genderKey: ['women'] },
  { key: '5', label: '5', parentKey: 'suits-separates', genderKey: ['women'] },
  { key: '6', label: '6', parentKey: 'suits-separates', genderKey: ['women'] },
  { key: '7', label: '7', parentKey: 'suits-separates', genderKey: ['women'] },
  { key: '8', label: '8', parentKey: 'suits-separates', genderKey: ['women'] },
  { key: '9', label: '9', parentKey: 'suits-separates', genderKey: ['women'] },
  { key: '10', label: '10', parentKey: 'suits-separates', genderKey: ['women'] },
  { key: '11', label: '11', parentKey: 'suits-separates', genderKey: ['women'] },
  { key: '12', label: '12', parentKey: 'suits-separates', genderKey: ['women'] },
  { key: '13', label: '13', parentKey: 'suits-separates', genderKey: ['women'] },
  { key: '15', label: '15', parentKey: 'suits-separates', genderKey: ['women'] },
  { key: '17', label: '17', parentKey: 'suits-separates', genderKey: ['women'] },
  { key: 'XXS', label: 'XXS', parentKey: 'suits-separates', genderKey: ['women'] },
  { key: 'XS', label: 'XS', parentKey: 'suits-separates', genderKey: ['women'] },
  { key: 'S', label: 'S', parentKey: 'suits-separates', genderKey: ['women'] },
  { key: 'M', label: 'M', parentKey: 'suits-separates', genderKey: ['women'] },
  { key: 'L', label: 'L', parentKey: 'suits-separates', genderKey: ['women'] },
  { key: 'XL', label: 'XL', parentKey: 'suits-separates', genderKey: ['women'] },
  { key: 'XXL', label: 'XXL', parentKey: 'suits-separates', genderKey: ['women'] },
  { key: '00P', label: '00P', parentKey: 'suits-separates', genderKey: ['women'] },
  { key: '0P', label: '0P', parentKey: 'suits-separates', genderKey: ['women'] },
  { key: '2P', label: '2P', parentKey: 'suits-separates', genderKey: ['women'] },
  { key: '4P', label: '4P', parentKey: 'suits-separates', genderKey: ['women'] },
  { key: '6P', label: '6P', parentKey: 'suits-separates', genderKey: ['women'] },
  { key: '8P', label: '8P', parentKey: 'suits-separates', genderKey: ['women'] },
  { key: '10P', label: '10P', parentKey: 'suits-separates', genderKey: ['women'] },
  { key: '12P', label: '12P', parentKey: 'suits-separates', genderKey: ['women'] },
  { key: '14P', label: '14P', parentKey: 'suits-separates', genderKey: ['women'] },
  { key: '16P', label: '16P', parentKey: 'suits-separates', genderKey: ['women'] },
  { key: '18P', label: '18P', parentKey: 'suits-separates', genderKey: ['women'] },
  { key: '20P', label: '20P', parentKey: 'suits-separates', genderKey: ['women'] },
  { key: 'XXSP', label: 'XXSP', parentKey: 'suits-separates', genderKey: ['women'] },
  { key: 'XSP', label: 'XSP', parentKey: 'suits-separates', genderKey: ['women'] },
  { key: 'SP', label: 'SP', parentKey: 'suits-separates', genderKey: ['women'] },
  { key: 'MP', label: 'MP', parentKey: 'suits-separates', genderKey: ['women'] },
  { key: 'LP', label: 'L P', parentKey: 'suits-separates', genderKey: ['women'] },
  { key: 'XLP', label: 'XLP', parentKey: 'suits-separates', genderKey: ['women'] },
  { key: 'XXLP', label: 'XXLP', parentKey: 'suits-separates', genderKey: ['women'] },

  { key: 'one-size', label: 'One Size', parentKey: 'sweaters', genderKey: ['women'] },
  { key: 'custom', label: 'Custom', parentKey: 'sweaters', genderKey: ['women'] },

  { key: 'XS', label: 'XS', parentKey: 'sweaters', genderKey: ['men'] },
  { key: 'S', label: 'S', parentKey: 'sweaters', genderKey: ['men'] },
  { key: 'M', label: 'M', parentKey: 'sweaters', genderKey: ['men'] },
  { key: 'L', label: 'L', parentKey: 'sweaters', genderKey: ['men'] },
  { key: 'XL', label: 'XL', parentKey: 'sweaters', genderKey: ['men'] },
  { key: 'XXL', label: 'XXL', parentKey: 'sweaters', genderKey: ['men'] },
  { key: '3XL', label: '3XL', parentKey: 'sweaters', genderKey: ['men'] },
  { key: '4XL', label: '4XL', parentKey: 'sweaters', genderKey: ['men'] },
  { key: '5XL', label: '5XL', parentKey: 'sweaters', genderKey: ['men'] },
  { key: '6XL', label: '6XL', parentKey: 'sweaters', genderKey: ['men'] },
  { key: 'LT', label: 'LT', parentKey: 'sweaters', genderKey: ['men'] },
  { key: 'XLT', label: 'XLT', parentKey: 'sweaters', genderKey: ['men'] },
  { key: '2XLT', label: '2XLT', parentKey: 'sweaters', genderKey: ['men'] },
  { key: '3XLT', label: '3XLT', parentKey: 'sweaters', genderKey: ['men'] },
  { key: '4XLT', label: '4XLT', parentKey: 'sweaters', genderKey: ['men'] },
  { key: '5XLT', label: '5XLT', parentKey: 'sweaters', genderKey: ['men'] },

  { key: 'XXS', label: 'XXS', parentKey: 'sweaters', genderKey: ['women'] },
  { key: '00', label: '00', parentKey: 'sweaters', genderKey: ['women'] },
  { key: '0', label: '0', parentKey: 'sweaters', genderKey: ['women'] },
  { key: '2', label: '2', parentKey: 'sweaters', genderKey: ['women'] },
  { key: '4', label: '4', parentKey: 'sweaters', genderKey: ['women'] },
  { key: '6', label: '6', parentKey: 'sweaters', genderKey: ['women'] },
  { key: '8', label: '8', parentKey: 'sweaters', genderKey: ['women'] },
  { key: '10', label: '10', parentKey: 'sweaters', genderKey: ['women'] },
  { key: '12', label: '12', parentKey: 'sweaters', genderKey: ['women'] },
  { key: 'XXL', label: 'XXL', parentKey: 'sweaters', genderKey: ['women'] },
  { key: 'XXXL', label: 'XXXL', parentKey: 'sweaters', genderKey: ['women'] },
  { key: '0X', label: '0X', parentKey: 'sweaters', genderKey: ['women'] },
  { key: '1X', label: '1X', parentKey: 'sweaters', genderKey: ['women'] },
  { key: '2X', label: '2X', parentKey: 'sweaters', genderKey: ['women'] },
  { key: '3X', label: '3X', parentKey: 'sweaters', genderKey: ['women'] },
  { key: '4X', label: '4X', parentKey: 'sweaters', genderKey: ['women'] },
  { key: '5X', label: '5X', parentKey: 'sweaters', genderKey: ['women'] },
  { key: '14', label: '14', parentKey: 'sweaters', genderKey: ['women'] },
  { key: '14W', label: '14W', parentKey: 'sweaters', genderKey: ['women'] },
  { key: '16', label: '16', parentKey: 'sweaters', genderKey: ['women'] },
  { key: '16W', label: '16W', parentKey: 'sweaters', genderKey: ['women'] },
  { key: '18', label: '18', parentKey: 'sweaters', genderKey: ['women'] },
  { key: '18W', label: '18W', parentKey: 'sweaters', genderKey: ['women'] },
  { key: '20', label: '20', parentKey: 'sweaters', genderKey: ['women'] },
  { key: '20W', label: '20W', parentKey: 'sweaters', genderKey: ['women'] },
  { key: '22', label: '22', parentKey: 'sweaters', genderKey: ['women'] },
  { key: '22W', label: '22W', parentKey: 'sweaters', genderKey: ['women'] },
  { key: '24', label: '24', parentKey: 'sweaters', genderKey: ['women'] },
  { key: '24W', label: '24W', parentKey: 'sweaters', genderKey: ['women'] },
  { key: '26', label: '26', parentKey: 'sweaters', genderKey: ['women'] },
  { key: '26W', label: '26W', parentKey: 'sweaters', genderKey: ['women'] },
  { key: '28', label: '28', parentKey: 'sweaters', genderKey: ['women'] },
  { key: '28W', label: '28W', parentKey: 'sweaters', genderKey: ['women'] },
  { key: '30', label: '30', parentKey: 'sweaters', genderKey: ['women'] },
  { key: '30W', label: '30W', parentKey: 'sweaters', genderKey: ['women'] },
  { key: '32', label: '32', parentKey: 'sweaters', genderKey: ['women'] },
  { key: '32W', label: '32W', parentKey: 'sweaters', genderKey: ['women'] },
  { key: '00', label: '00', parentKey: 'sweaters', genderKey: ['women'] },
  { key: '0', label: '0', parentKey: 'sweaters', genderKey: ['women'] },
  { key: '1', label: '1', parentKey: 'sweaters', genderKey: ['women'] },
  { key: '2', label: '2', parentKey: 'sweaters', genderKey: ['women'] },
  { key: '3', label: '3', parentKey: 'sweaters', genderKey: ['women'] },
  { key: '4', label: '4', parentKey: 'sweaters', genderKey: ['women'] },
  { key: '5', label: '5', parentKey: 'sweaters', genderKey: ['women'] },
  { key: '6', label: '6', parentKey: 'sweaters', genderKey: ['women'] },
  { key: '7', label: '7', parentKey: 'sweaters', genderKey: ['women'] },
  { key: '8', label: '8', parentKey: 'sweaters', genderKey: ['women'] },
  { key: '9', label: '9', parentKey: 'sweaters', genderKey: ['women'] },
  { key: '10', label: '10', parentKey: 'sweaters', genderKey: ['women'] },
  { key: '11', label: '11', parentKey: 'sweaters', genderKey: ['women'] },
  { key: '12', label: '12', parentKey: 'sweaters', genderKey: ['women'] },
  { key: '13', label: '13', parentKey: 'sweaters', genderKey: ['women'] },
  { key: '15', label: '15', parentKey: 'sweaters', genderKey: ['women'] },
  { key: '17', label: '17', parentKey: 'sweaters', genderKey: ['women'] },
  { key: 'XXS', label: 'XXS', parentKey: 'sweaters', genderKey: ['women'] },
  { key: 'XS', label: 'XS', parentKey: 'sweaters', genderKey: ['women'] },
  { key: 'S', label: 'S', parentKey: 'sweaters', genderKey: ['women'] },
  { key: 'M', label: 'M', parentKey: 'sweaters', genderKey: ['women'] },
  { key: 'L', label: 'L', parentKey: 'sweaters', genderKey: ['women'] },
  { key: 'XL', label: 'XL', parentKey: 'sweaters', genderKey: ['women'] },
  { key: 'XXL', label: 'XXL', parentKey: 'sweaters', genderKey: ['women'] },
  { key: '00P', label: '00P', parentKey: 'sweaters', genderKey: ['women'] },
  { key: '0P', label: '0P', parentKey: 'sweaters', genderKey: ['women'] },
  { key: '2P', label: '2P', parentKey: 'sweaters', genderKey: ['women'] },
  { key: '4P', label: '4P', parentKey: 'sweaters', genderKey: ['women'] },
  { key: '6P', label: '6P', parentKey: 'sweaters', genderKey: ['women'] },
  { key: '8P', label: '8P', parentKey: 'sweaters', genderKey: ['women'] },
  { key: '10P', label: '10P', parentKey: 'sweaters', genderKey: ['women'] },
  { key: '12P', label: '12P', parentKey: 'sweaters', genderKey: ['women'] },
  { key: '14P', label: '14P', parentKey: 'sweaters', genderKey: ['women'] },
  { key: '16P', label: '16P', parentKey: 'sweaters', genderKey: ['women'] },
  { key: '18P', label: '18P', parentKey: 'sweaters', genderKey: ['women'] },
  { key: '20P', label: '20P', parentKey: 'sweaters', genderKey: ['women'] },
  { key: 'XXSP', label: 'XXSP', parentKey: 'sweaters', genderKey: ['women'] },
  { key: 'XSP', label: 'XSP', parentKey: 'sweaters', genderKey: ['women'] },
  { key: 'SP', label: 'SP', parentKey: 'sweaters', genderKey: ['women'] },
  { key: 'MP', label: 'MP', parentKey: 'sweaters', genderKey: ['women'] },
  { key: 'LP', label: 'LP', parentKey: 'sweaters', genderKey: ['women'] },
  { key: 'XLP', label: 'XLP', parentKey: 'sweaters', genderKey: ['women'] },
  { key: 'XXLP', label: 'XXLP', parentKey: 'sweaters', genderKey: ['women'] },
  { key: 'other', label: 'Other', parentKey: 'other', genderKey: ['women', 'men'] },
];

export const colors = [
  { key: 'black', label: 'Black' },
  { key: 'white', label: 'White' },
  { key: 'gray', label: 'Gray' },
  { key: 'blue', label: 'Blue' },
  { key: 'red', label: 'Red' },
  { key: 'beige', label: 'Beige' },
  { key: 'brown', label: 'Brown' },
  { key: 'cream', label: 'Cream' },
  { key: 'green', label: 'Green' },
  { key: 'orange', label: 'Orange' },
  { key: 'yellow', label: 'Yellow' },
  { key: 'silver', label: 'Silver' },
  { key: 'gold', label: 'Gold' },
  { key: 'pink', label: 'Pink' },
  { key: 'purple', label: 'Purple' },
  { key: 'camo', label: 'Camo' },
  { key: 'tan', label: 'Tan' },
  // { key: 'multi-color', label: 'Multi-Color' },
];

export const subCategory = [
  {
    key: 'belts',
    label: 'Belts',
    parentKey: 'accessories',
    genderKey: ['women', 'men'],
  },
  {
    key: 'electronic-cases',
    label: 'Electronic Cases',
    parentKey: 'accessories',
    genderKey: ['women', 'men'],
  },
  {
    key: 'face-masks',
    label: 'Face Masks',
    parentKey: 'accessories',
    genderKey: ['women', 'men'],
  },
  {
    key: 'glasses',
    label: 'Glasses',
    parentKey: 'accessories',
    genderKey: ['women', 'men'],
  },
  {
    key: 'gloves-mittens',
    label: 'Gloves & Mittens',
    parentKey: 'accessories',
    genderKey: ['women'],
  },
  {
    key: 'gloves-scarves',
    label: 'Gloves & Scarves',
    parentKey: 'accessories',
    genderKey: ['men'],
  },
  {
    key: 'hair-accessories',
    label: 'Hair Accessories',
    parentKey: 'accessories',
    genderKey: ['women'],
  },
  {
    key: 'hats',
    label: 'Hats',
    parentKey: 'accessories',
    genderKey: ['women', 'men'],
  },
  {
    key: 'hosiery',
    label: 'Hosiery',
    parentKey: 'accessories',
    genderKey: ['women'],
  },
  {
    key: 'jewelry-watches',
    label: 'Jewelry & Watches',
    parentKey: 'accessories',
    genderKey: ['men'],
  },
  {
    key: 'pajamas-robes',
    label: 'Pajamas & Robes',
    parentKey: 'accessories',
    genderKey: ['men'],
  },
  {
    key: 'scarves-wraps',
    label: 'Scarves & Wraps',
    parentKey: 'accessories',
    genderKey: ['women'],
  },
  {
    key: 'socks',
    label: 'Socks',
    parentKey: 'accessories',
    genderKey: ['women'],
  },
  {
    key: 'sunglasses',
    label: 'Sunglasses',
    parentKey: 'accessories',
    genderKey: ['men', 'women'],
  },
  {
    key: 'ties-pocket-squares',
    label: 'Ties & Pocket Squares',
    parentKey: 'accessories',
    genderKey: ['men'],
  },
  {
    key: 'umbrellas',
    label: 'Umbrellas',
    parentKey: 'accessories',
    genderKey: ['men', 'women'],
  },
  {
    key: 'watches',
    label: 'Watches',
    parentKey: 'accessories',
    genderKey: ['women'],
  },

  //bags

  {
    key: 'baby-bags',
    label: 'Baby Bags',
    parentKey: 'bags',
    genderKey: ['women'],
  },
  {
    key: 'backpacks',
    label: 'Backpacks',
    parentKey: 'bags',
    genderKey: ['men', 'women'],
  },
  {
    key: 'belt-bags',
    label: 'Belt Bags',
    parentKey: 'bags',
    genderKey: ['men', 'women'],
  },
  {
    key: 'briefcases',
    label: 'Briefcases',
    parentKey: 'bags',
    genderKey: ['men'],
  },
  {
    key: 'clutches-wristlets',
    label: 'Clutches & Wristlets',
    parentKey: 'bags',
    genderKey: ['women'],
  },
  {
    key: 'cosmetic-bags',
    label: 'Cosmetic Bags',
    parentKey: 'bags',
    genderKey: ['women'],
  },
  {
    key: 'crossbody-bags',
    label: 'Crossbody Bags',
    parentKey: 'bags',
    genderKey: ['women'],
  },
  {
    key: 'duffle-bags',
    label: 'Duffle Bags',
    parentKey: 'bags',
    genderKey: ['men'],
  },
  {
    key: 'hobos',
    label: 'Hobos',
    parentKey: 'bags',
    genderKey: ['women'],
  },
  {
    key: 'laptop-bags',
    label: 'Laptop Bags',
    parentKey: 'bags',
    genderKey: ['men', 'women'],
  },
  {
    key: 'luggage-travel-bags',
    label: 'Luggage & Travel Bags',
    parentKey: 'bags',
    genderKey: ['men', 'women'],
  },
  {
    key: 'messenger-bags',
    label: 'Messenger Bags',
    parentKey: 'bags',
    genderKey: ['men'],
  },
  {
    key: 'satchels',
    label: 'Satchels',
    parentKey: 'bags',
    genderKey: ['women'],
  },
  {
    key: 'shoulder-bags',
    label: 'Shoulder Bags',
    parentKey: 'bags',
    genderKey: ['women'],
  },
  {
    key: 'totes',
    label: 'Totes',
    parentKey: 'bags',
    genderKey: ['women'],
  },
  {
    key: 'toiletry-bags',
    label: 'Toiletry Bags',
    parentKey: 'bags',
    genderKey: ['men'],
  },
  {
    key: 'wallets',
    label: 'Wallets',
    parentKey: 'bags',
    genderKey: ['men', 'women'],
  },

  //Costumes
  {
    key: 'dance',
    label: 'Dance',
    parentKey: 'costumes',
    genderKey: ['men', 'women'],
  },
  {
    key: 'halloween',
    label: 'Halloween',
    parentKey: 'costumes',
    genderKey: ['men', 'women'],
  },
  {
    key: 'seasonal',
    label: 'Seasonal',
    parentKey: 'costumes',
    genderKey: ['men', 'women'],
  },
  {
    key: 'superhero',
    label: 'Superhero',
    parentKey: 'costumes',
    genderKey: ['men', 'women'],
  },
  {
    key: 'theater',
    label: 'Theater',
    parentKey: 'costumes',
    genderKey: ['men', 'women'],
  },

  //dresses
  {
    key: 'asymmetrical',
    label: 'Asymmetrical',
    parentKey: 'dresses',
    genderKey: ['women'],
  },
  {
    key: 'high-low',
    label: 'High Low',
    parentKey: 'dresses',
    genderKey: ['women'],
  },
  {
    key: 'long-sleeve',
    label: 'Long Sleeve',
    parentKey: 'dresses',
    genderKey: ['women'],
  },
  {
    key: 'maxi',
    label: 'Maxi',
    parentKey: 'dresses',
    genderKey: ['women'],
  },
  {
    key: 'midi',
    label: 'Midi',
    parentKey: 'dresses',
    genderKey: ['women'],
  },
  {
    key: 'mini',
    label: 'Mini',
    parentKey: 'dresses',
    genderKey: ['women'],
  },
  {
    key: 'prom',
    label: 'Prom',
    parentKey: 'dresses',
    genderKey: ['women'],
  },
  {
    key: 'rompers',
    label: 'Rompers',
    parentKey: 'dresses',
    genderKey: ['women'],
  },
  {
    key: 'strapless',
    label: 'Strapless',
    parentKey: 'dresses',
    genderKey: ['women'],
  },
  {
    key: 'wedding',
    label: 'Wedding',
    parentKey: 'dresses',
    genderKey: ['women'],
  },

  //intimates-sleepwear
  {
    key: 'bras',
    label: 'Bras',
    parentKey: 'intimates-sleepwear',
    genderKey: ['women'],
  },
  {
    key: 'chemises-slips',
    label: 'Chemises & Slips',
    parentKey: 'intimates-sleepwear',
    genderKey: ['women'],
  },
  {
    key: 'pajamas',
    label: 'Pajamas',
    parentKey: 'intimates-sleepwear',
    genderKey: ['women'],
  },
  {
    key: 'panties',
    label: 'Panties',
    parentKey: 'intimates-sleepwear',
    genderKey: ['women'],
  },
  {
    key: 'robes',
    label: 'Robes',
    parentKey: 'intimates-sleepwear',
    genderKey: ['women'],
  },
  {
    key: 'shapewear',
    label: 'Shapewear',
    parentKey: 'intimates-sleepwear',
    genderKey: ['women'],
  },

  //Jackets & Coats
  {
    key: 'active',
    label: 'Active',
    parentKey: 'jackets-coats',
    genderKey: ['men', 'women'],
  },
  {
    key: 'blazers',
    label: 'Blazers',
    parentKey: 'jackets-coats',
    genderKey: ['men', 'women'],
  },
  {
    key: 'bombers',
    label: 'Bombers',
    parentKey: 'jackets-coats',
    genderKey: ['men', 'women'],
  },
  {
    key: 'capes',
    label: 'Capes',
    parentKey: 'jackets-coats',
    genderKey: ['men'],
  },
  {
    key: 'capes-ponchos',
    label: 'Capes & Ponchos',
    parentKey: 'jackets-coats',
    genderKey: ['women'],
  },
  {
    key: 'denim-utility',
    label: 'Denim & Utility',
    parentKey: 'jackets-coats',
    genderKey: ['men', 'women'],
  },
  {
    key: 'down-puffer',
    label: 'Down & Puffer',
    parentKey: 'jackets-coats',
    genderKey: ['men', 'women'],
  },
  {
    key: 'fleece',
    label: 'Fleece',
    parentKey: 'jackets-coats',
    genderKey: ['men', 'women'],
  },
  {
    key: 'fur-faux-fur',
    label: 'Fur & Faux Fur',
    parentKey: 'jackets-coats',
    genderKey: ['men', 'women'],
  },
  {
    key: 'leather-faux-leather',
    label: 'Leather & Faux Leather',
    parentKey: 'jackets-coats',
    genderKey: ['men', 'women'],
  },
  {
    key: 'rain-coats',
    label: 'Raincoats',
    parentKey: 'jackets-coats',
    genderKey: ['men', 'women'],
  },
  {
    key: 'ski',
    label: 'Ski',
    parentKey: 'jackets-coats',
    genderKey: ['men', 'women'],
  },
  {
    key: 'teddy',
    label: 'Teddy',
    parentKey: 'jackets-coats',
    genderKey: ['women'],
  },
  {
    key: 'trench-coats',
    label: 'Trench Coats',
    parentKey: 'jackets-coats',
    genderKey: ['men', 'women'],
  },
  {
    key: 'varsity',
    label: 'Varsity',
    parentKey: 'jackets-coats',
    genderKey: ['men', 'women'],
  },
  {
    key: 'vests',
    label: 'Vests',
    parentKey: 'jackets-coats',
    genderKey: ['men', 'women'],
  },
  {
    key: 'windbreakers',
    label: 'Windbreakers',
    parentKey: 'jackets-coats',
    genderKey: ['men', 'women'],
  },
  {
    key: 'wool-pea-coats',
    label: 'Wool & Pea Coats',
    parentKey: 'jackets-coats',
    genderKey: ['men', 'women'],
  },

  //jeans
  {
    key: 'cropped-ankle',
    label: 'Ankle & Cropped',
    parentKey: 'jeans',
    genderKey: ['women'],
  },
  {
    key: 'boot-cut',
    label: 'Boot Cut',
    parentKey: 'jeans',
    genderKey: ['men', 'women'],
  },
  {
    key: 'flare-wide-leg',
    label: 'Flare & Wide Leg',
    parentKey: 'jeans',
    genderKey: ['women'],
  },
  {
    key: 'high-rise',
    label: 'High Rise',
    parentKey: 'jeans',
    genderKey: ['women'],
  },
  {
    key: 'jeggings',
    label: 'Jeggings',
    parentKey: 'jeans',
    genderKey: ['women'],
  },
  {
    key: 'overalls',
    label: 'Overalls',
    parentKey: 'jeans',
    genderKey: ['women'],
  },
  {
    key: 'relaxed',
    label: 'Relaxed',
    parentKey: 'jeans',
    genderKey: ['men', 'women'],
  },
  {
    key: 'skinny',
    label: 'Skinny',
    parentKey: 'jeans',
    genderKey: ['men', 'women'],
  },
  {
    key: 'slim',
    label: 'Slim',
    parentKey: 'jeans',
    genderKey: ['men'],
  },
  {
    key: 'straight',
    label: 'Straight',
    parentKey: 'jeans',
    genderKey: ['men'],
  },
  {
    key: 'straight-leg',
    label: 'Straight Leg',
    parentKey: 'jeans',
    genderKey: ['women'],
  },

  //Jewelry

  {
    key: 'bracelets',
    label: 'Bracelets',
    parentKey: 'jewelry',
    genderKey: ['women'],
  },
  {
    key: 'brooches',
    label: 'Brooches',
    parentKey: 'jewelry',
    genderKey: ['women'],
  },
  {
    key: 'earrings',
    label: 'Earrings',
    parentKey: 'jewelry',
    genderKey: ['women'],
  },
  {
    key: 'necklaces',
    label: 'Necklaces',
    parentKey: 'jewelry',
    genderKey: ['women'],
  },
  {
    key: 'rings',
    label: 'Rings',
    parentKey: 'jewelry',
    genderKey: ['women'],
  },

  //pants
  {
    key: 'cropped-ankle',
    label: 'Ankle & Cropped',
    parentKey: 'pants',
    genderKey: ['women'],
  },
  {
    key: 'bootcut-flare',
    label: 'Bootcut & Flare',
    parentKey: 'pants',
    genderKey: ['women'],
  },
  {
    key: 'cargo',
    label: 'Cargo',
    parentKey: 'pants',
    genderKey: ['men'],
  },
  {
    key: 'chinos-khakis',
    label: 'Chinos & Khakis',
    parentKey: 'pants',
    genderKey: ['men'],
  },
  {
    key: 'corduroy',
    label: 'Corduroy',
    parentKey: 'pants',
    genderKey: ['men'],
  },
  {
    key: 'capris',
    label: 'Capris',
    parentKey: 'pants',
    genderKey: ['women'],
  },
  {
    key: 'dress',
    label: 'Dress',
    parentKey: 'pants',
    genderKey: ['men'],
  },
  {
    key: 'high-waisted',
    label: 'High Waisted',
    parentKey: 'pants',
    genderKey: ['women'],
  },
  {
    key: 'leggings',
    label: 'Leggings',
    parentKey: 'pants',
    genderKey: ['women'],
  },
  {
    key: 'overalls-jumpsuits',
    label: 'Overalls & Jumpsuits',
    parentKey: 'pants',
    genderKey: ['women'],
  },
  {
    key: 'skinny',
    label: 'Skinny',
    parentKey: 'pants',
    genderKey: ['women'],
  },
  {
    key: 'straight-leg',
    label: 'Straight Leg',
    parentKey: 'pants',
    genderKey: ['women'],
  },
  {
    key: 'sweatpants-joggers',
    label: 'Sweatpants & Joggers',
    parentKey: 'pants',
    genderKey: ['men', 'women'],
  },
  {
    key: 'trousers-wide-leg',
    label: 'Trousers & Wide Leg',
    parentKey: 'pants',
    genderKey: ['women'],
  },

  //Shirts
  {
    key: 'casual-button-downs',
    label: 'Casual Button Downs',
    parentKey: 'shirts',
    genderKey: ['men'],
  },
  {
    key: 'dress-shirts',
    label: 'Dress Shirts',
    parentKey: 'shirts',
    genderKey: ['men'],
  },
  {
    key: 'jerseys',
    label: 'Jerseys',
    parentKey: 'shirts',
    genderKey: ['men'],
  },
  {
    key: 'polos',
    label: 'Polos',
    parentKey: 'shirts',
    genderKey: ['men'],
  },
  {
    key: 'sweatshirts-hoodies',
    label: 'Sweatshirts & Hoodies',
    parentKey: 'shirts',
    genderKey: ['men'],
  },
  {
    key: 'tank-tops',
    label: 'Tank Tops',
    parentKey: 'shirts',
    genderKey: ['men'],
  },
  {
    key: 'tees-long-sleeve',
    label: 'Tees: Long Sleeve',
    parentKey: 'shirts',
    genderKey: ['men'],
  },
  {
    key: 'tees-short-sleeve',
    label: 'Tees: Short Sleeve',
    parentKey: 'shirts',
    genderKey: ['men'],
  },
  {
    key: 'other',
    label: 'Other',
    parentKey: 'shirts',
    genderKey: ['men'],
  },

  //shoes
  {
    key: 'boat-shoes',
    label: 'Boat Shoes',
    parentKey: 'shoes',
    genderKey: ['men'],
  },
  {
    key: 'boots',
    label: 'Boots',
    parentKey: 'shoes',
    genderKey: ['men'],
  },
  {
    key: 'boots-booties',
    label: 'Boots & Booties',
    parentKey: 'shoes',
    genderKey: ['women'],
  },
  {
    key: 'casual-shoes',
    label: 'Casual Shoes',
    parentKey: 'shoes',
    genderKey: ['men'],
  },
  {
    key: 'clogs-mules',
    label: 'Clogs & Mules',
    parentKey: 'shoes',
    genderKey: ['women'],
  },
  {
    key: 'dress-shoes',
    label: 'Dress Shoes',
    parentKey: 'shoes',
    genderKey: ['men'],
  },
  {
    key: 'espadrilles',
    label: 'Espadrilles',
    parentKey: 'shoes',
    genderKey: ['women'],
  },
  {
    key: 'flats-loafers',
    label: 'Flats & Loafers',
    parentKey: 'shoes',
    genderKey: ['women'],
  },
  {
    key: 'heels',
    label: 'Heels',
    parentKey: 'shoes',
    genderKey: ['women'],
  },
  {
    key: 'loafers-drivers',
    label: 'Loafers & Drivers',
    parentKey: 'shoes',
    genderKey: ['men'],
  },
  {
    key: 'narrow',
    label: 'Narrow',
    parentKey: 'shoes',
    genderKey: ['women'],
  },
  {
    key: 'sandals-flip-flops',
    label: 'Sandals & Flip Flops',
    parentKey: 'shoes',
    genderKey: ['men', 'women'],
  },
  {
    key: 'slippers',
    label: 'Slippers',
    parentKey: 'shoes',
    genderKey: ['men', 'women'],
  },
  {
    key: 'single-shoes',
    label: 'Single Shoes',
    parentKey: 'shoes',
    genderKey: ['women'],
  },
  {
    key: 'wedges',
    label: 'Wedges',
    parentKey: 'shoes',
    genderKey: ['women'],
  },
  {
    key: 'wide',
    label: 'Wide',
    parentKey: 'shoes',
    genderKey: ['women'],
  },
  {
    key: 'other',
    label: 'Other',
    parentKey: 'shoes',
    genderKey: ['men', 'women'],
  },

  //shorts
  {
    key: 'active',
    label: 'Active',
    parentKey: 'shorts',
    genderKey: ['women'],
  },
  {
    key: 'athletic',
    label: 'Athletic',
    parentKey: 'shorts',
    genderKey: ['men'],
  },
  {
    key: 'bermudas',
    label: 'Bermudas',
    parentKey: 'shorts',
    genderKey: ['women'],
  },
  {
    key: 'bikers',
    label: 'Bikers',
    parentKey: 'shorts',
    genderKey: ['women'],
  },
  {
    key: 'cargo',
    label: 'Cargo',
    parentKey: 'shorts',
    genderKey: ['men', 'women'],
  },
  {
    key: 'chino',
    label: 'Chino',
    parentKey: 'shorts',
    genderKey: ['men', 'women'],
  },
  {
    key: 'denim',
    label: 'Denim',
    parentKey: 'shorts',
    genderKey: ['men', 'women'],
  },
  {
    key: 'high-waist',
    label: 'High Waist',
    parentKey: 'shorts',
    genderKey: ['women'],
  },
  {
    key: 'hybrids',
    label: 'Hybrids',
    parentKey: 'shorts',
    genderKey: ['men'],
  },
  {
    key: 'short-shorts',
    label: 'Short Shorts',
    parentKey: 'shorts',
    genderKey: ['women'],
  },
  {
    key: 'skorts',
    label: 'Skorts',
    parentKey: 'shorts',
    genderKey: ['women'],
  },
  // {
  //   key: 'other',
  //   label: 'Other',
  //   parentKey: 'shorts',
  //   genderKey: ['men', 'women'],
  // },

  //Socks & Underwear 
  {
    key: 'athletic-socks',
    label: 'Athletic Socks',
    parentKey: 'socks-underwear',
    genderKey: ['men'],
  },
  {
    key: 'boxer-briefs',
    label: 'Boxer Briefs',
    parentKey: 'socks-underwear',
    genderKey: ['men'],
  },
  {
    key: 'boxers',
    label: 'Boxers',
    parentKey: 'socks-underwear',
    genderKey: ['men'],
  },
  {
    key: 'briefs',
    label: 'Briefs',
    parentKey: 'socks-underwear',
    genderKey: ['men'],
  },
  {
    key: 'casual-socks',
    label: 'Casual Socks',
    parentKey: 'socks-underwear',
    genderKey: ['men'],
  },
  {
    key: 'dress-socks',
    label: 'Dress Socks',
    parentKey: 'socks-underwear',
    genderKey: ['men'],
  },
  {
    key: 'undershirts',
    label: 'Undershirts',
    parentKey: 'socks-underwear',
    genderKey: ['men'],
  },
  {
    key: 'other',
    label: 'Other',
    parentKey: 'socks-underwear',
    genderKey: ['men'],
  },

  //Skirts 
  {
    key: 'a-line',
    label: 'A Line',
    parentKey: 'skirts',
    genderKey: ['women'],
  },
  {
    key: 'asymmetrical',
    label: 'Asymmetrical',
    parentKey: 'skirts',
    genderKey: ['women'],
  },
  {
    key: 'denim',
    label: 'Denim',
    parentKey: 'skirts',
    genderKey: ['women'],
  },
  {
    key: 'high-low',
    label: 'High Low',
    parentKey: 'skirts',
    genderKey: ['women'],
  },
  {
    key: 'maxi',
    label: 'Maxi',
    parentKey: 'skirts',
    genderKey: ['women'],
  },
  {
    key: 'midi',
    label: 'Midi',
    parentKey: 'skirts',
    genderKey: ['women'],
  },
  {
    key: 'mini',
    label: 'Mini',
    parentKey: 'skirts',
    genderKey: ['women'],
  },
  {
    key: 'pencil',
    label: 'Pencil',
    parentKey: 'skirts',
    genderKey: ['women'],
  },
  {
    key: 'pleated',
    label: 'Pleated',
    parentKey: 'skirts',
    genderKey: ['women'],
  },
  {
    key: 'other',
    label: 'Other',
    parentKey: 'skirts',
    genderKey: ['women'],
  },

  //Suits & Separates
  {
    key: 'suits-separates',
    label: 'Suits & Separates',
    parentKey: 'suits-separates',
    genderKey: ['women'],
  },
  {
    key: 'other',
    label: 'Other',
    parentKey: 'suits-separates',
    genderKey: ['women'],
  },

  //Suits & Tuxedos
  {
    key: 'suits-tuxedos',
    label: 'Suits & Tuxedos',
    parentKey: 'suits-tuxedos',
    genderKey: ['men'],
  },
  {
    key: 'other',
    label: 'Other',
    parentKey: 'suits-tuxedos',
    genderKey: ['men'],
  },

  //Sweaters
  {
    key: 'cardigans',
    label: 'Cardigans',
    parentKey: 'sweaters',
    genderKey: ['women','men'],
  },
  {
    key: 'cashmere',
    label: 'Cashmere',
    parentKey: 'sweaters',
    genderKey: ['women','men'],
  },
  {
    key: 'cold-shoulder',
    label: 'Cold Shoulder',
    parentKey: 'sweaters',
    genderKey: ['women'],
  },
  {
    key: 'cowl-turtleneck',
    label: 'Cowl & Turtleneck',
    parentKey: 'sweaters',
    genderKey: ['women'],
  },
  {
    key: 'crewnecks',
    label: 'Crewnecks',
    parentKey: 'sweaters',
    genderKey: ['women','men'],
  },
  {
    key: 'crew-scoop-necks',
    label: 'Crew & Scoop Necks',
    parentKey: 'sweaters',
    genderKey: ['women'],
  },
  {
    key: 'cropped',
    label: 'Cropped',
    parentKey: 'sweaters',
    genderKey: ['women'],
  },
  {
    key: 'hoodie',
    label: 'Hoodie',
    parentKey: 'sweaters',
    genderKey: ['women','men'],
  },
  {
    key: 'ponchos',
    label: 'Ponchos',
    parentKey: 'sweaters',
    genderKey: ['women'],
  },
  {
    key: 'shrugs',
    label: 'Shrugs',
    parentKey: 'sweaters',
    genderKey: ['women'],
  },
  {
    key: 'tunic',
    label: 'Tunic',
    parentKey: 'sweaters',
    genderKey: ['women'],
  },
  {
    key: 'turtlenecks',
    label: 'Turtlenecks',
    parentKey: 'sweaters',
    genderKey: ['women','men'],
  },
  {
    key: 'vests',
    label: 'Vests',
    parentKey: 'sweaters',
    genderKey: ['women','men'],
  },
  {
    key: 'v-neck',
    label: 'V-Neck',
    parentKey: 'sweaters',
    genderKey: ['women','men'],
  },
  {
    key: 'wrap',
    label: 'Wrap',
    parentKey: 'sweaters',
    genderKey: ['women'],
  },
  {
    key: 'zip-up',
    label: 'Zip-Up',
    parentKey: 'sweaters',
    genderKey: ['men'],
  },
  {
    key: 'other',
    label: 'Other',
    parentKey: 'sweaters',
    genderKey: ['women'],
  },

  //swim
  {
    key: 'board-shorts',
    label: 'Board Shorts',
    parentKey: 'swim',
    genderKey: ['men'],
  },
  {
    key: 'hybrids',
    label: 'Hybrids',
    parentKey: 'swim',
    genderKey: ['men'],
  },
  {
    key: 'rash-guards',
    label: 'Rash Guards',
    parentKey: 'swim',
    genderKey: ['men'],
  },
  {
    key: 'swim-trunks',
    label: 'Swim Trunks',
    parentKey: 'swim',
    genderKey: ['men'],
  },

  //swimsuits
  {
    key: 'coverups',
    label: 'Cover-Ups',
    parentKey: 'swimsuits',
    genderKey: ['women'],
  },
  {
    key: 'bikinis',
    label: 'Bikinis',
    parentKey: 'swimsuits',
    genderKey: ['women'],
  },
  {
    key: 'one-pieces',
    label: 'One Pieces',
    parentKey: 'swimsuits',
    genderKey: ['women'],
  },
  {
    key: 'tankinis',
    label: 'Tankinis',
    parentKey: 'swimsuits',
    genderKey: ['women'],
  },

  //tops
  {
    key: 'blouses',
    label: 'Blouses',
    parentKey: 'tops',
    genderKey: ['women'],
  },
  {
    key: 'bodysuits',
    label: 'Bodysuits',
    parentKey: 'tops',
    genderKey: ['women'],
  },
  {
    key: 'button-down-shirts',
    label: 'Button Down Shirts',
    parentKey: 'tops',
    genderKey: ['women'],
  },
  {
    key: 'crop-tops',
    label: 'Crop Tops',
    parentKey: 'tops',
    genderKey: ['women'],
  },
  {
    key: 'sweatshirts-hoodies',
    label: 'Sweatshirts & Hoodies',
    parentKey: 'tops',
    genderKey: ['women'],
  },
  {
    key: 'tank-tops',
    label: 'Tank Tops',
    parentKey: 'tops',
    genderKey: ['women'],
  },
  {
    key: 'tees-long-sleeve',
    label: 'Tees: Long Sleeve',
    parentKey: 'tops',
    genderKey: ['women'],
  },
  {
    key: 'tees-short-sleeve',
    label: 'Tees: Short Sleeve',
    parentKey: 'tops',
    genderKey: ['women'],
  },
  {
    key: 'tunics',
    label: 'Tunics',
    parentKey: 'tops',
    genderKey: ['women'],
  },
  {
    key: 'other',
    label: 'Other',
    parentKey: 'tops',
    genderKey: ['women'],
  },

  //sneakers
  {
    key: 'basketball',
    label: 'Basketball',
    parentKey: 'sneakers',
    genderKey: ['men', 'women'],
  },
  {
    key: 'casual',
    label: 'Casual',
    parentKey: 'sneakers',
    genderKey: ['men', 'women'],
  },
  {
    key: 'luxury',
    label: 'Luxury',
    parentKey: 'sneakers',
    genderKey: ['men', 'women'],
  },
  {
    key: 'running',
    label: 'Running',
    parentKey: 'sneakers',
    genderKey: ['men', 'women'],
  },
  {
    key: 'soccer',
    label: 'Soccer',
    parentKey: 'sneakers',
    genderKey: ['men', 'women'],
  },
  {
    key: 'skateboard',
    label: 'Skateboard',
    parentKey: 'sneakers',
    genderKey: ['men', 'women'],
  },
  {
    key: 'slids',
    label: 'Slids',
    parentKey: 'sneakers',
    genderKey: ['men', 'women'],
  },
  {
    key: 'tennis',
    label: 'Tennis',
    parentKey: 'sneakers',
    genderKey: ['men', 'women'],
  },
  {
    key: 'track-field',
    label: 'Track & Field',
    parentKey: 'sneakers',
    genderKey: ['men', 'women'],
  },
  {
    key: 'training',
    label: 'Training',
    parentKey: 'sneakers',
    genderKey: ['men', 'women'],
  },
  {
    key: 'walking',
    label: 'Walking',
    parentKey: 'sneakers',
    genderKey: ['men', 'women'],
  },
  {
    key: 'other',
    label: 'Other',
    parentKey: 'sneakers',
    genderKey: ['men', 'women'],
  },
  {
    key: 'other',
    label: 'Other',
    parentKey: 'other',
    genderKey: ['men', 'women'],
  },
];

export const gender = [
  { key: 'men', label: 'Men' },
  { key: 'women', label: 'Women' },
];

export const price = [
  { key: 'under-$25', label: 'Under $25', min: 0, max: 25 },
  { key: '$25-$50', label: '$25 - $50', min: 25, max: 50 },
  { key: '$50-$100', label: '$50 - $100', min: 50, max: 100 },
  { key: '$100-$250', label: '$100 - $250', min: 100, max: 250 },
  { key: '$250-$500', label: '$250 - $500', min: 250, max: 500 },
  { key: 'over-$500', label: 'Over $500', min: 500, max: 1000 },
];
export const category = [
  {
    key: 'tops',
    label: 'Tops',
    parentKey: ['clothing', 'streetwear', 'vintage'],
    genderKey: ['women'],
  },
  {
    key: 'pants',
    label: 'Bottoms',
    parentKey: ['clothing', 'streetwear', 'vintage'],
    genderKey: ['men', 'women'],
  },
  {
    key: 'bags',
    label: 'Bags',
    parentKey: ['clothing', 'streetwear', 'vintage'],
    genderKey: ['men', 'women'],
  },
  {
    key: 'dresses',
    label: 'Dresses',
    parentKey: ['clothing', 'streetwear', 'vintage'],
    genderKey: ['women'],
  },
  {
    key: 'shoes',
    label: 'Men Shoes',
    parentKey: ['clothing', 'streetwear', 'vintage'],
    genderKey: ['men'],
  },
  {
    key: 'shoes',
    label: 'Women Shoes',
    parentKey: ['clothing', 'streetwear', 'vintage'],
    genderKey: ['women'],
  },
  {
    key: 'accessories',
    label: 'Accessories',
    parentKey: ['clothing', 'streetwear', 'vintage'],
    genderKey: ['men', 'women'],
  },
  {
    key: 'jackets-coats',
    label: 'Coats',
    parentKey: ['clothing', 'streetwear', 'vintage'],
    genderKey: ['men', 'women'],
  },
  {
    key: 'suits-separates',
    label: 'Suits',
    parentKey: ['clothing', 'streetwear', 'vintage'],
    genderKey: ['women'],
  },
  {
    key: 'suits-tuxedos',
    label: 'Suits',
    parentKey: ['clothing', 'streetwear', 'vintage'],
    genderKey: ['men'],
  },
  {
    key: 'swim',
    label: 'Swim',
    parentKey: ['clothing', 'streetwear', 'vintage'],
    genderKey: ['men'],
  },
  {
    key: 'swimsuits',
    label: 'Swimsuit',
    parentKey: ['clothing', 'streetwear', 'vintage'],
    genderKey: ['women'],
  },
  {
    key: 'jewelry',
    label: 'Jewelry',
    parentKey: ['clothing', 'streetwear', 'vintage'],
    genderKey: ['women','men'],
  },
  {
    key: 'other',
    label: 'Other',
    parentKey: ['clothing', 'streetwear', 'vintage', 'sneakers'],
    genderKey: ['men', 'women'],
  },
  {
    key: 'all',
    label: 'All',
    parentKey: ['clothing', 'streetwear', 'vintage', 'sneakers'],
    genderKey: ['men', 'women'],
  },
];

export const sneakersSubCategory = [
  {
    key: 'basketball',
    label: 'Basketball',
    parentKey: 'sneakers',
    genderKey: ['men', 'women'],
  },
  {
    key: 'casual',
    label: 'Casual',
    parentKey: 'sneakers',
    genderKey: ['men', 'women'],
  },
  {
    key: 'luxury',
    label: 'Luxury',
    parentKey: 'sneakers',
    genderKey: ['men', 'women'],
  },
  {
    key: 'running',
    label: 'Running',
    parentKey: 'sneakers',
    genderKey: ['men', 'women'],
  },
  {
    key: 'soccer',
    label: 'Soccer',
    parentKey: 'sneakers',
    genderKey: ['men', 'women'],
  },
  {
    key: 'skateboard',
    label: 'Skateboard',
    parentKey: 'sneakers',
    genderKey: ['men', 'women'],
  },
  {
    key: 'slids',
    label: 'Slids',
    parentKey: 'sneakers',
    genderKey: ['men', 'women'],
  },
  {
    key: 'tennis',
    label: 'Tennis',
    parentKey: 'sneakers',
    genderKey: ['men', 'women'],
  },
  {
    key: 'track-field',
    label: 'Track & Field',
    parentKey: 'sneakers',
    genderKey: ['men', 'women'],
  },
  {
    key: 'training',
    label: 'Training',
    parentKey: 'sneakers',
    genderKey: ['men', 'women'],
  },
  {
    key: 'walking',
    label: 'Walking',
    parentKey: 'sneakers',
    genderKey: ['men', 'women'],
  },
  {
    key: 'other',
    label: 'Other',
    parentKey: 'sneakers',
    genderKey: ['men', 'women'],
  },
]

export const subCategoryWithTax = [
  'electronic-cases',
  'glasses',
  'hair-accessories',
  'jewelry-watches',
  'sunglasses',
  'umbrellas',
  'watches',
  'baby-bags',
  'backpacks',
  'belt-bags',
  'briefcases',
  'clutches-wristlets',
  'cosmetic-bags',
  'crossbody-bags',
  'duffle-bags',
  'hobos',
  'laptop-bags',
  'luggage-travel-bags',
  'messenger-bags',
  'satchels',
  'totes',
  'toiletry-bags',
  'wallets',
  'dance',
  'halloween',
  'seasonal',
  'superhero',
  'theater',
  'bracelets',
  'brooches',
  'earrings',
  'necklaces',
  'rings',
];

export const filters = [
  {
    id: 'category',
    label: 'Category',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_category'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        {
          key: 'accessories',
          label: 'Accessories',
          parentKey: ['clothing', 'streetwear', 'vintage'],
          genderKey: ['men', 'women'],
        },
        {
          key: 'bags',
          label: 'Bags',
          parentKey: ['clothing', 'streetwear', 'vintage'],
          genderKey: ['men', 'women'],
        },
        {
          key: 'dresses',
          label: 'Dresses',
          parentKey: ['clothing', 'streetwear', 'vintage'],
          genderKey: ['women'],
        },
        {
          key: 'intimates-sleepwear',
          label: 'Intimates & Sleepwear',
          parentKey: ['clothing', 'streetwear', 'vintage'],
          genderKey: ['women'],
        },
        {
          key: 'jackets-coats',
          label: 'Jackets & Coats',
          parentKey: ['clothing', 'streetwear', 'vintage'],
          genderKey: ['men', 'women'],
        },
        {
          key: 'jeans',
          label: 'Jeans',
          parentKey: ['clothing', 'streetwear', 'vintage'],
          genderKey: ['men', 'women'],
        },
        {
          key: 'jewelry',
          label: 'Jewelry',
          parentKey: ['clothing', 'streetwear', 'vintage'],
          genderKey: ['women'],
        },
        {
          key: 'pants',
          label: 'Pants',
          parentKey: ['clothing', 'streetwear', 'vintage'],
          genderKey: ['men', 'women'],
        },
        {
          key: 'shirts',
          label: 'Shirts',
          parentKey: ['clothing', 'streetwear', 'vintage'],
          genderKey: ['men'],
        },
        {
          key: 'shoes',
          label: 'Shoes',
          parentKey: ['clothing', 'streetwear', 'vintage'],
          genderKey: ['men', 'women'],
        },       
        {
          key: 'shorts',
          label: 'Shorts',
          parentKey: ['clothing', 'streetwear', 'vintage'],
          genderKey: ['men', 'women'],
        },
        {
          key: 'socks-underwear',
          label: 'Socks & Underwear',
          parentKey: ['clothing', 'streetwear', 'vintage'],
          genderKey: ['men'],
        },
        {
          key: 'skirts',
          label: 'Skirts',
          parentKey: ['clothing', 'streetwear', 'vintage'],
          genderKey: ['women'],
        },
        {
          key: 'suits-separates',
          label: 'Suits & Separates',
          parentKey: ['clothing', 'streetwear', 'vintage'],
          genderKey: ['women'],
        },
        {
          key: 'suits-tuxedos',
          label: 'Suits & Tuxedos',
          parentKey: ['clothing', 'streetwear', 'vintage'],
          genderKey: ['men'],
        },
        {
          key: 'sweaters',
          label: 'Sweaters',
          parentKey: ['clothing', 'streetwear', 'vintage'],
          genderKey: ['women','men'],
        },
        {
          key: 'swim',
          label: 'Swim',
          parentKey: ['clothing', 'streetwear', 'vintage'],
          genderKey: ['men'],
        },
        {
          key: 'swimsuits',
          label: 'Swimsuits',
          parentKey: ['clothing', 'streetwear', 'vintage'],
          genderKey: ['women'],
        },
        {
          key: 'tops',
          label: 'Tops',
          parentKey: ['clothing', 'streetwear', 'vintage'],
          genderKey: ['women'],
        },
        {
          key: 'costumes',
          label: 'Costumes',
          parentKey: ['clothing', 'streetwear', 'vintage'],
          genderKey: ['men', 'women'],
        },
        {
          key: 'sneakers',
          label: 'Sneakers',
          parentKey: ['sneakers'],
          genderKey: ['men', 'women'],
        },
        {
          key: 'other',
          label: 'Other',
          parentKey: ['clothing', 'streetwear', 'vintage', 'sneakers'],
          genderKey: ['men', 'women'],
        },
      ],
    },
  },

  {
    id: 'subCategory',
    label: 'Sub Category',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_subCategory'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: subCategory,
    },
  },
  {
    id: 'gender',
    label: 'Gender',
    type: 'KeywordFilter',
    group: 'primary',
    queryParamNames: ['pub_gender'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: gender,
    },
  },
  {
    id: 'size',
    label: 'Size',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_size'],
    config: {
      // Schema type options: 'enum', 'multi-enum'
      // Both types can work so that user selects multiple values when filtering search results.
      // With "enum" the functionality will be OR-semantics (Nike OR Adidas OR Salomon)
      // With "multi-enum" it's possible to use both AND and OR semantics with searchMode config.
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: sizes,
    },
  },
  {
    id: 'condition',
    label: 'Condition',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_condition'],
    config: {
      // Schema type options: 'enum', 'multi-enum'
      // Both types can work so that user selects multiple values when filtering search results.
      // With "enum" the functionality will be OR-semantics (Nike OR Adidas OR Salomon)
      // With "multi-enum" it's possible to use both AND and OR semantics with searchMode config.
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'used', label: 'Used' },
        { key: 'new-with-tag', label: 'NWT (New With Tag)' },
        { key: 'new-without-tag', label: 'NWOT (New Without Tag)' },
      ],
    },
  },
  {
    id: 'color',
    label: 'Color',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_color'],
    config: {
      // Schema type options: 'enum', 'multi-enum'
      // Both types can work so that user selects multiple values when filtering search results.
      // With "enum" the functionality will be OR-semantics (Nike OR Adidas OR Salomon)
      // With "multi-enum" it's possible to use both AND and OR semantics with searchMode config.
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: colors,
    },
  },
  {
    id: 'availability',
    label: 'Availability',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_availability'],
    config: {
      // Schema type options: 'enum', 'multi-enum'
      // Both types can work so that user selects multiple values when filtering search results.
      // With "enum" the functionality will be OR-semantics (Nike OR Adidas OR Salomon)
      // With "multi-enum" it's possible to use both AND and OR semantics with searchMode config.
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: availabilities,
    },
  },
  {
    id: 'brand',
    label: 'Brand',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_brand'],
    config: {
      // Schema type options: 'enum', 'multi-enum'
      // Both types can work so that user selects multiple values when filtering search results.
      // With "enum" the functionality will be OR-semantics (Nike OR Adidas OR Salomon)
      // With "multi-enum" it's possible to use both AND and OR semantics with searchMode config.
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'adidas', label: 'Adidas' },
        { key: 'air_jordan', label: 'Air Jordan' },
        { key: 'converse', label: 'Converse' },
        { key: 'new_balance', label: 'New Balance' },
        { key: 'nike', label: 'Nike' },
        { key: 'puma', label: 'Puma' },
        { key: 'ultraboost', label: 'Ultraboost' },
        { key: 'vans', label: 'Vans' },
        { key: 'yeezy', label: 'Yeezy' },
        { key: 'other', label: 'Other' },
      ],
    },
  },
  {
    id: 'price',
    label: 'Budget',
    type: 'PriceFilter',
    group: 'primary',
    queryParamNames: ['price'],
    config: {
      // Schema type options: 'enum', 'multi-enum'
      // Both types can work so that user selects multiple values when filtering search results.
      // With "enum" the functionality will be OR-semantics (Nike OR Adidas OR Salomon)
      // With "multi-enum" it's possible to use both AND and OR semantics with searchMode config.
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: price,
    },
  },
  // {
  //   id: 'price',
  //   label: 'Price',
  //   type: 'PriceFilter',
  //   group: 'primary',
  //   // Note: PriceFilter is fixed filter,
  //   // you can't change "queryParamNames: ['price'],"
  //   queryParamNames: ['price'],
  //   // Price filter configuration
  //   // Note: unlike most prices this is not handled in subunits
  //   config: {
  //     min: 0,
  //     max: 1000,
  //     step: 5,
  //   },
  // },
  {
    id: 'keyword',
    label: 'Keyword',
    type: 'KeywordFilter',
    group: 'primary',
    // Note: KeywordFilter is fixed filter,
    // you can't change "queryParamNames: ['keywords'],"
    queryParamNames: ['keywords'],
    // NOTE: If you are ordering search results by distance
    // the keyword search can't be used at the same time.
    // You can turn on/off ordering by distance from config.js file.
    config: {},
  },

  // Here is an example of multi-enum search filter.
  //
  // {
  //   id: 'amenities',
  //   label: 'Amenities',
  //   type: 'SelectMultipleFilter',
  //   group: 'secondary',
  //   queryParamNames: ['pub_amenities'],
  //   config: {
  //     // Schema type options: 'enum', 'multi-enum'
  //     // Both types can work so that user selects multiple values when filtering search results.
  //     // With "enum" the functionality will be OR-semantics (Nike OR Adidas OR Salomon)
  //     // With "multi-enum" it's possible to use both AND and OR semantics with searchMode config.
  //     schemaType: 'multi-enum',

  //     // Optional modes: 'has_all', 'has_any'
  //     // Note: this is relevant only for schema type 'multi-enum'
  //     // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
  //     searchMode: 'has_all',

  //     // "key" is the option you see in Flex Console.
  //     // "label" is set here for this web app's UI only.
  //     // Note: label is not added through the translation files
  //     // to make filter customizations a bit easier.
  //     options: [
  //       { key: 'towels', label: 'Towels' },
  //       { key: 'bathroom', label: 'Bathroom' },
  //       { key: 'swimming_pool', label: 'Swimming pool' },
  //       { key: 'barbeque', label: 'Barbeque' },
  //     ],
  //   },
  // },
];

export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Flex API expects it to be named as 'sort'
  queryParamName: 'sort',

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  // Relevance key is used with keywords filter.
  // Keywords filter also sorts results according to relevance.
  relevanceFilter: 'keywords',

  // Keyword filter is sorting the results by relevance.
  // If keyword filter is active, one might want to disable other sorting options
  // by adding 'keyword' to this list.
  conflictingFilters: [],

  options: [
    { key: 'createdAt', label: 'Newest' },
    { key: '-createdAt', label: 'Oldest' },
    { key: '-price', label: 'Lowest price' },
    { key: 'price', label: 'Highest price' },

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    { key: 'relevance', label: 'Relevance', longLabel: 'Relevance (Keyword search)' },
  ],
};

export const listing = {
  // These should be listing details from public data with schema type: enum
  // SectionDetailsMaybe component shows these on listing page.
  enumFieldDetails: ['gender', 'category', 'subCategory'],
};
