import React from 'react';
import { arrayOf, bool, number, oneOf, shape, string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import classNames from 'classnames';

import config from '../../config';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import {
  txIsCanceled,
  txIsEnquired,
  txIsPurchased,
  txIsDelivered,
  txIsDisputed,
  txIsPaymentExpired,
  txIsPaymentPending,
  txIsReceived,
  txIsCompleted,
  txIsReviewedByCustomer,
  txIsReviewedByProvider,
  txIsReviewed,
  txIsOfferAccepted,
  txIsMakeAnOffer,
  txIsOfferCountered,
  txIsCounterOfferAccepted,
  txIsOfferDeclined,
  txIsCounterOfferDeclined,
  txIsOfferExpired,
  txIsCounterOfferExpired,
  txIsDisputeAccepted,
  txIsDisputeReturnLabel,
  txIsDisputeRefunded,
  txIsDisputeCancel,
  txIsPrintLabel,
} from '../../util/transaction';
import { propTypes, DATE_TYPE_DATE } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import { formatDateIntoPartials } from '../../util/dates';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import {
  Avatar,
  NamedLink,
  NotificationBadge,
  Page,
  PaginationLinks,
  TabNav,
  LayoutSideNavigation,
  LayoutWrapperMain,
  LayoutWrapperSideNav,
  LayoutWrapperTopbar,
  LayoutWrapperFooter,
  Footer,
  IconSpinner,
  UserDisplayName,
  Skeleton,
  LayoutWrapperAccountSettingsSideNav,
  LayoutSingleColumn,
  Button,
} from '../../components';

import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer';
import NotFoundPage from '../../containers/NotFoundPage/NotFoundPage';

import css from './InboxPage.module.css';
import { updatedMetaData } from './InboxPage.duck';

// Translated name of the state of the given transaction
export const txState = (intl, tx, type) => {
  const isOrder = type === 'order';

  if (txIsEnquired(tx)) {
    return {
      lastTransitionedAtClassName: css.lastTransitionedAtEmphasized,
      stateClassName: css.stateActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.stateEnquiry',
      }),
    };
  } else if (txIsMakeAnOffer(tx)) {
    return {
      stateClassName: isOrder ? css.stateActionNeeded : css.stateNoActionNeeded,
      state: isOrder
        ? intl.formatMessage({
          id: 'InboxPage.stateMakeAnOffer',
        })
        : intl.formatMessage({
          id: 'InboxPage.stateMakeAnOffer2',
        }),
    };
  } else if (txIsOfferAccepted(tx)) {
    return {
      stateClassName: isOrder ? css.stateActionNeeded : css.stateNoActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.stateOfferAccepted',
      }),
    };
  } else if (txIsOfferDeclined(tx)) {
    return {
      stateClassName: isOrder ? css.stateActionNeeded : css.stateNoActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.stateOfferDeclined',
      }),
    };
  } else if (txIsOfferExpired(tx)) {
    return {
      stateClassName: isOrder ? css.stateActionNeeded : css.stateNoActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.stateOfferExpire',
      }),
    };
  } else if (txIsCounterOfferDeclined(tx)) {
    return {
      stateClassName: isOrder ? css.stateActionNeeded : css.stateNoActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.stateCounterOfferDeclined',
      }),
    };
  } else if (txIsCounterOfferExpired(tx)) {
    return {
      stateClassName: isOrder ? css.stateActionNeeded : css.stateNoActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.stateCounterOfferExpire',
      }),
    };
  } else if (txIsOfferCountered(tx)) {
    return {
      stateClassName: isOrder ? css.stateActionNeeded : css.stateNoActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.stateOfferCountered',
      }),
    };
  } else if (txIsCounterOfferAccepted(tx)) {
    return {
      stateClassName: isOrder ? css.stateActionNeeded : css.stateNoActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.stateCounterOfferAccepted',
      }),
    };
  } else if (txIsPaymentPending(tx)) {
    return {
      stateClassName: isOrder ? css.stateActionNeeded : css.stateNoActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.statePendingPayment',
      }),
    };
  } else if (txIsPaymentExpired(tx)) {
    return {
      stateClassName: css.stateNoActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.statePaymentExpired',
      }),
    };
  } else if (txIsCanceled(tx)) {
    return {
      stateClassName: css.stateConcluded,
      state: intl.formatMessage({
        id: 'InboxPage.stateCanceled',
      }),
    };
  } else if (txIsPurchased(tx)) {
    return {
      stateClassName: isOrder ? css.stateNoActionNeeded : css.stateActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.statePurchased',
      }),
    };
  } else if (txIsPrintLabel(tx)) {
    return {
      stateClassName: isOrder ? css.stateNoActionNeeded : css.stateActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.stateShipped',
      }),
    };
  } else if (txIsDelivered(tx)) {
    return isOrder
      ? {
        stateClassName: css.stateActionNeeded,
        state: intl.formatMessage({ id: 'InboxPage.stateDeliveredCustomer' }),
      }
      : {
        stateClassName: css.stateNoActionNeeded,
        state: intl.formatMessage({ id: 'InboxPage.stateDeliveredProvider' }),
      };
  } else if (txIsDisputed(tx)) {
    return {
      stateClassName: css.stateActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.stateDisputed',
      }),
    };
  } else if (txIsDisputeAccepted(tx)) {
    return {
      stateClassName: css.stateActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.stateDisputeAccepted',
      }),
    };
  }
  else if (txIsDisputeCancel(tx)) {
    return {
      stateClassName: css.stateActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.stateDisputeCancel',
      }),
    };
  }
  else if (txIsDisputeReturnLabel(tx)) {
    return {
      stateClassName: css.stateActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.stateDisputeReturnLabel',
      }),
    };
  }
  else if (txIsDisputeRefunded(tx)) {
    return {
      stateClassName: css.stateActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.stateDisputeRefunded',
      }),
    };
  }
  else if (txIsReceived(tx) || txIsCompleted(tx)) {
    return {
      stateClassName: css.stateActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.stateReceived',
      }),
    };
  } else if (txIsReviewedByCustomer(tx)) {
    const translationKey = isOrder ? 'InboxPage.stateReviewGiven' : 'InboxPage.stateReviewNeeded';
    return {
      stateClassName: isOrder ? css.stateNoActionNeeded : css.stateActionNeeded,
      state: intl.formatMessage({
        id: translationKey,
      }),
    };
  } else if (txIsReviewedByProvider(tx)) {
    const translationKey = isOrder ? 'InboxPage.stateReviewNeeded' : 'InboxPage.stateReviewGiven';
    return {
      stateClassName: isOrder ? css.stateActionNeeded : css.stateNoActionNeeded,
      state: intl.formatMessage({
        id: translationKey,
      }),
    };
  } else if (txIsReviewed(tx)) {
    return {
      stateClassName: css.stateConcluded,
      state: intl.formatMessage({
        id: 'InboxPage.stateReviewed',
      }),
    };
  } else {
    console.warn('This transition is unknown:', tx.attributes.lastTransition);
    return null;
  }
};

export const InboxItem = props => {
  const { unitType, type, tx, intl, stateData, currentUser, onUpdatedMetaData, updateInProgress } = props;
  const { customer, provider, listing } = tx;
  const { metadata } = tx.attributes || {};
  const isOrder = customer?.id?.uuid === currentUser?.id?.uuid;

  const unitPurchase = tx.attributes?.lineItems?.find(
    item => item.code === unitType && !item.reversal
  );
  const quantity = unitPurchase ? unitPurchase.quantity.toString() : null;

  const otherUser = isOrder ? provider : customer;
  const otherUserDisplayName = <UserDisplayName user={otherUser} intl={intl} />;
  const isOtherUserBanned = otherUser.attributes.banned;

  const isSaleNotification = !isOrder && txIsPurchased(tx);
  const rowNotificationDot = isSaleNotification ? <div className={css.notificationDot} /> : null;
  const lastTransitionedAt = formatDateIntoPartials(tx.attributes.lastTransitionedAt, intl);

  const linkClasses = classNames(css.itemLink, {
    [css.bannedUserLink]: isOtherUserBanned,
  });

  return (
    <div className={css.item}>
      <div className={css.itemAvatar}>
        <Avatar user={otherUser} />
      </div>
      <NamedLink
        className={linkClasses}
        name={isOrder ? 'OrderDetailsPage' : 'SaleDetailsPage'}
        params={{ id: tx.id.uuid }}
      >
        <div className={css.rowNotificationDot}>{rowNotificationDot}</div>
        <div className={css.itemInfo}>
          <div className={css.itemUsername}>{otherUserDisplayName}</div>
          <div className={css.itemOrderInfo}>
            <span>{listing?.attributes?.title}</span>
            <br />
            <FormattedMessage id="InboxPage.quantity" values={{ quantity }} />
          </div>
        </div>
        <div className={css.itemState}>
          <div className={classNames(css.stateName, stateData.stateClassName)}>
            {stateData.state}
          </div>
          <div
            className={classNames(css.lastTransitionedAt, stateData.lastTransitionedAtClassName)}
            title={lastTransitionedAt.dateAndTime}
          >
            {lastTransitionedAt.date}
          </div>
        </div>
      </NamedLink>
      <div className={css.delete}>
        <Button inProgress={updateInProgress == tx.id.uuid} onClick={() => {
          const data = {
            id: tx.id.uuid,
            ...metadata,
            isDeleted: true
          }
          onUpdatedMetaData(data)
        }}>Delete</Button>
      </div>
    </div>
  );
};

InboxItem.propTypes = {
  unitType: propTypes.lineItemUnitType.isRequired,
  type: oneOf(['order', 'sale']).isRequired,
  tx: propTypes.transaction.isRequired,
  intl: intlShape.isRequired,
};

export const InboxPageComponent = props => {
  const {
    unitType,
    currentUser,
    fetchInProgress,
    fetchOrdersOrSalesError,
    intl,
    pagination,
    params,
    providerNotificationCount,
    scrollingDisabled,
    transactions,
    onUpdatedMetaData,
    updateInProgress,
  } = props;
  const { tab } = params;
  const ensuredCurrentUser = ensureCurrentUser(currentUser);

  const validTab = tab === 'orders' || tab === 'sales';
  if (!validTab) {
    return <NotFoundPage />;
  }

  const isOrders = tab === 'orders';

  const ordersTitle = intl.formatMessage({ id: 'InboxPage.ordersTitle' });
  const salesTitle = intl.formatMessage({ id: 'InboxPage.salesTitle' });
  const title = isOrders ? ordersTitle : salesTitle;
  const loadingResults = (
    <>
      <div className={css.listingCard}>
        <div style={{ display: 'flex', gap: '20px', alignItems: 'center', marginBottom: '30px' }}>
          <div>
            <Skeleton width="35px" height={'35px'} rounded={100} bottom={0} />
          </div>
          <div style={{ width: '30%' }}>
            <Skeleton width="100%" height={'8px'} bottom={4} />
            <Skeleton width="70%" height={'8px'} bottom={4} />
            <Skeleton width="60%" height={'8px'} bottom={0} />
          </div>
          <div style={{ maxWidth: '10%', flex: 1, marginLeft: 'auto' }}>
            <Skeleton width="100%" height={'8px'} bottom={4} />
            <Skeleton width="60%" height={'8px'} bottom={0} />
          </div>
        </div>
        <div style={{ display: 'flex', gap: '20px', alignItems: 'center', marginBottom: '30px' }}>
          <div>
            <Skeleton width="35px" height={'35px'} rounded={100} bottom={0} />
          </div>
          <div style={{ width: '30%' }}>
            <Skeleton width="100%" height={'8px'} bottom={4} />
            <Skeleton width="70%" height={'8px'} bottom={4} />
            <Skeleton width="60%" height={'8px'} bottom={0} />
          </div>
          <div style={{ maxWidth: '10%', flex: 1, marginLeft: 'auto' }}>
            <Skeleton width="100%" height={'8px'} bottom={4} />
            <Skeleton width="60%" height={'8px'} bottom={0} />
          </div>
        </div>
        <div style={{ display: 'flex', gap: '20px', alignItems: 'center', marginBottom: '30px' }}>
          <div>
            <Skeleton width="35px" height={'35px'} rounded={100} bottom={0} />
          </div>
          <div style={{ width: '30%' }}>
            <Skeleton width="100%" height={'8px'} bottom={4} />
            <Skeleton width="70%" height={'8px'} bottom={4} />
            <Skeleton width="60%" height={'8px'} bottom={0} />
          </div>
          <div style={{ maxWidth: '10%', flex: 1, marginLeft: 'auto' }}>
            <Skeleton width="100%" height={'8px'} bottom={4} />
            <Skeleton width="60%" height={'8px'} bottom={0} />
          </div>
        </div>
      </div>
    </>
  );
  const toTxItem = tx => {
    const type = isOrders ? 'order' : 'sale';
    const stateData = txState(intl, tx, type);

    // Render InboxItem only if the latest transition of the transaction is handled in the `txState` function.
    return stateData ? (
      <li key={tx.id.uuid} className={css.listItem}>
        <InboxItem
          unitType={unitType}
          type={type}
          tx={tx}
          intl={intl}
          stateData={stateData}
          currentUser={currentUser}
          onUpdatedMetaData={onUpdatedMetaData}
          updateInProgress={updateInProgress}
        />
      </li>
    ) : null;
  };

  const error = fetchOrdersOrSalesError ? (
    <p className={css.error}>
      <FormattedMessage id="InboxPage.fetchFailed" />
    </p>
  ) : null;

  const noResults =
    !fetchInProgress && transactions.length === 0 && !fetchOrdersOrSalesError ? (
      <li key="noResults" className={css.noResults}>
        <FormattedMessage id={isOrders ? 'InboxPage.noOrdersFound' : 'InboxPage.noSalesFound'} />
      </li>
    ) : null;

  const hasOrderOrSaleTransactions = (tx, isOrdersTab, user) => {
    return isOrdersTab
      ? user.id && tx && tx.length > 0 && tx[0].customer.id.uuid === user.id.uuid
      : user.id && tx && tx.length > 0 && tx[0].provider.id.uuid === user.id.uuid;
  };
  const hasTransactions =
    !fetchInProgress && hasOrderOrSaleTransactions(transactions, isOrders, ensuredCurrentUser);
  const pagingLinks =
    hasTransactions && pagination && pagination.totalPages > 1 ? (
      <PaginationLinks
        className={css.pagination}
        pageName="InboxPage"
        pagePathParams={params}
        pagination={pagination}
      />
    ) : null;

  const providerNotificationBadge =
    providerNotificationCount > 0 ? <NotificationBadge count={providerNotificationCount} /> : null;
  const tabs = [
    {
      text: (
        <span>
          <FormattedMessage id="InboxPage.ordersTabTitle" />
        </span>
      ),
      selected: isOrders,
      linkProps: {
        name: 'InboxPage',
        params: { tab: 'orders' },
      },
    },
    {
      text: (
        <span>
          <FormattedMessage id="InboxPage.salesTabTitle" />
          {/* {providerNotificationBadge} */}
        </span>
      ),
      selected: !isOrders,
      linkProps: {
        name: 'InboxPage',
        params: { tab: 'sales' },
      },
    },
  ];
  const nav = <TabNav rootClassName={css.tabs} tabRootClassName={css.tab} tabs={tabs} />;

  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer
            className={css.topbar}
            mobileRootClassName={css.mobileTopbar}
            desktopClassName={css.desktopTopbar}
            currentPage="InboxPage"
          />
        </LayoutWrapperTopbar>
        {/* <LayoutWrapperAccountSettingsSideNav currentTab="InboxPage" /> */}
        <LayoutWrapperMain>
          <div className={css.inboxMainWrapper}>
            {nav}
            <div className={css.contentWrapper}>
              {error}
              <ul className={css.itemList}>
                {!fetchInProgress ? (
                  transactions.filter((e) => !e.attributes.metadata.isDeleted).map(toTxItem)
                ) : (
                  <li className={css.listItemsLoading}>
                    <IconSpinner />
                  </li>
                )}
                {fetchInProgress && loadingResults}
                {noResults}
              </ul>
              {pagingLinks}
            </div>
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

InboxPageComponent.defaultProps = {
  unitType: config.lineItemUnitType,
  currentUser: null,
  currentUserHasOrders: null,
  fetchOrdersOrSalesError: null,
  pagination: null,
  providerNotificationCount: 0,
  sendVerificationEmailError: null,
};

InboxPageComponent.propTypes = {
  params: shape({
    tab: string.isRequired,
  }).isRequired,

  unitType: propTypes.lineItemUnitType,
  currentUser: propTypes.currentUser,
  fetchInProgress: bool.isRequired,
  fetchOrdersOrSalesError: propTypes.error,
  pagination: propTypes.pagination,
  providerNotificationCount: number,
  scrollingDisabled: bool.isRequired,
  transactions: arrayOf(propTypes.transaction).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { fetchInProgress, fetchOrdersOrSalesError, pagination, transactionRefs, updateInProgress } = state.InboxPage;
  const { currentUser, currentUserNotificationCount: providerNotificationCount } = state.user;
  return {
    currentUser,
    fetchInProgress,
    fetchOrdersOrSalesError,
    pagination,
    providerNotificationCount,
    scrollingDisabled: isScrollingDisabled(state),
    transactions: getMarketplaceEntities(state, transactionRefs),
    updateInProgress
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onUpdatedMetaData: (data) =>
      dispatch(updatedMetaData(data)),
  };
};

const InboxPage = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl)(InboxPageComponent);

export default InboxPage;
