/**
 *  TopbarMobileMenu prints the menu content for authenticated user or
 * shows login actions for those who are not authenticated.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ACCOUNT_SETTINGS_PAGES } from '../../../routing/routeConfiguration';
import { FormattedMessage } from '../../../util/reactIntl';
import { LINE_ITEM_UNITS, PUBLISHED, propTypes } from '../../../util/types';
import { ensureCurrentUser } from '../../../util/data';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

import {
  AvatarLarge,
  IconCollection,
  InlineTextButton,
  Menu,
  NamedLink,
  NotificationBadge,
  MenuLabel,
  MenuContent,
  MenuItem,
  IconSpinner,
} from '../../../components';

import css from './TopbarMobileMenu.module.css';
import OfferNotifications from '../TopbarDesktop/OfferNotifications';
import { txState } from '../../../containers/InboxPage/InboxPage';
import { getUserDetails } from '../../../util/dataExtractor';
import StarRatings from 'react-star-ratings';
import { listingStores } from '../../../config/marketplace-custom-config';
import { useSelector } from 'react-redux';

const calculateAverage = reviews => {
  if (reviews?.length === 0) {
    return 0;
  }
  const sum =
    reviews &&
    reviews?.reduce((accumulator, currentValue) => accumulator + currentValue.attributes.rating, 0);
  const average = sum / reviews?.length;
  return average;
};

const TopbarMobileMenu = props => {
  const state = useSelector(state=>state);
  const {earningTransactions} = state.user;
  const {
    isAuthenticated,
    currentPage,
    currentUserHasListings,
    currentUser,
    notificationCount,
    onLogout,
    fetchInProgress,
    intl,
    lb,
    bookmarks,
    offerTransactions,
    reviews,
    currentUserFollowerDetails,
    transactions,
    currentUserSaleLength
  } = props;

  const user = ensureCurrentUser(currentUser);
  const userName = currentUser?.id && getUserDetails(currentUser)?.userName;
  const { followers = [], following = [] } = currentUserFollowerDetails || {};
  // Calculate total amount using reduce
  const totalAmount = earningTransactions
    .filter(e => e.attributes.payoutTotal)
    .reduce((accumulator, currentValue) => {
      return parseInt(accumulator) + parseInt(currentValue?.attributes?.payoutTotal?.amount);
    }, 0);

  const toTxItem = tx => {
    const type = currentUser?.id?.uuid === tx?.provider?.id?.uuid ? 'sale' : 'order';
    const stateData = txState(intl, tx, type);

    // Render InboxItem only if the latest transition of the transaction is handled in the `txState` function.
    return stateData ? (
      <li key={tx.id.uuid} className={css.listItem}>
        <OfferNotifications
          unitType={LINE_ITEM_UNITS}
          type={type}
          tx={tx}
          intl={intl}
          stateData={stateData}
          isAuthenticated={isAuthenticated}
        />
      </li>
    ) : null;
  };

  if (!isAuthenticated) {
    const signup = (
      <NamedLink name="SignupPage" className={css.signupLink}>
        <FormattedMessage id="TopbarMobileMenu.signupLink" />
      </NamedLink>
    );

    const login = (
      <NamedLink name="LoginPage" className={css.loginLink}>
        <FormattedMessage id="TopbarMobileMenu.loginLink" />
      </NamedLink>
    );

    const signupOrLogin = (
      <span className={css.authenticationLinks}>
        <FormattedMessage id="TopbarMobileMenu.signupOrLogin" values={{ signup, login }} />
      </span>
    );
    return (
      <div className={css.root}>
        <div className={css.content}>
          <div className={css.authenticationGreeting}>
            <FormattedMessage
              id="TopbarMobileMenu.unauthorizedGreeting"
              values={{ lineBreak: <br />, signupOrLogin }}
            />
          </div>
        </div>
        <div className={css.footer}>
          <NamedLink className={css.createNewListingLink} name="NewListingPage">
            <FormattedMessage id="TopbarMobileMenu.newListingLink" />
          </NamedLink>
        </div>
      </div>
    );
  }

  const notificationCountBadge =
    notificationCount > 0 ? (
      <NotificationBadge className={css.notificationBadge} count={notificationCount} />
    ) : null;

  const displayName = user.attributes.profile.firstName;
  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };
  const readCount = offerTransactions.find(e => e.provider?.id?.uuid === currentUser?.id?.uuid)
    ? offerTransactions.length -
      offerTransactions.filter(e => e?.attributes?.metadata?.isProviderRead).length
    : offerTransactions.length -
      offerTransactions.filter(e => e?.attributes?.metadata?.isCustomerRead).length;

  const offersMenu = isAuthenticated ? (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        {isAuthenticated ? (
          <div className={classNames(css.navigationLink, currentPageClass('ManageListingsPage'))}>
            <span className={css.icon}>
              <span className={css.cartItemWrappper}>
                <span className={css.cartCount}>{readCount > 0 ? readCount : 0}</span>
                <IconCollection icon="OfferIcon" />
              </span>
            </span>
            <span className={css.createListing}>
              <FormattedMessage id="TopbarDesktop.Offers" />
            </span>
          </div>
        ) : null}
      </MenuLabel>
      <MenuContent className={css.OfferMenuContent}>
        <MenuItem key="ManageListingsPage">
          <div className={css.offerDropwownHeader}>
            <span>
              <IconCollection icon="OfferIcon" />
            </span>
            <h3>
              <FormattedMessage id="OfferNotifications.heading" />
            </h3>
            <span>|</span>
            <FormattedMessage
              id="OfferNotifications.newCount"
              values={{ count: readCount > 0 ? readCount : 0 }}
            />
          </div>

          <ul className={css.itemList}>
            {!fetchInProgress ? (
              offerTransactions?.map(toTxItem)
            ) : (
              <li className={css.listItemsLoading}>
                <IconSpinner />
              </li>
            )}
            {/* {"noResults"} */}
          </ul>
        </MenuItem>
      </MenuContent>
    </Menu>
  ) : null;

  return (
    <div className={css.root}>
      <div className={css.content}>
        {/* <span className={css.greeting}>
          <FormattedMessage id="TopbarMobileMenu.greeting" values={{ displayName }} />
        </span> */}

        {isAuthenticated ? offersMenu : null}
        {isAuthenticated ? (
          <NamedLink
            className={classNames(css.navigationLink, currentPageClass('ManageListingsPage'))}
            name="FavouriteListingsPage"
          >
            <span className={css.icon}>
              <IconCollection icon="IconLikeWhite" />
            </span>
            <span className={css.createListing}>
              <FormattedMessage id="TopbarDesktop.Likes" />
            </span>
          </NamedLink>
        ) : null}
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('ManageListingsPage'))}
          name="InboxPage"
          params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
        >
          <span className={css.icon}>
            <IconCollection icon="alertIcon" />
          </span>
          <span className={css.createListing}>
            <FormattedMessage id="TopbarDesktop.alert" />
          </span>
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('ManageListingsPage'))}
          name="CartPage"
        >
          <span className={css.icon}>
            <span className={css.cartItemWrappper}>
              <span className={css.cartCount}>
                {isAuthenticated ? (
                  bookmarks && bookmarks.length > 0 ? (
                    <span className={css.badge}>{bookmarks && `${bookmarks.length}`}</span>
                  ) : (
                    <span className={css.badge}>{lb && `${lb.length}`}</span>
                  )
                ) : lb && lb.length > 0 ? (
                  <span className={css.badge}>{lb && lb.length}</span>
                ) : null}
              </span>
              <span>
                <IconCollection icon="bagIcon" />
              </span>
            </span>
          </span>
          <span className={css.createListing}>
            <FormattedMessage id="TopbarDesktop.bag" />
          </span>
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('ManageListingsPage'))}
          name="ManageListingsPostPage"
          params={{ state: PUBLISHED }}
        >
          <span className={css.icon}>
            <IconCollection icon="IconEditListing" />
          </span>
          <FormattedMessage id="TopbarMobileMenu.yourListingsLink" />
        </NamedLink>
        <NamedLink className={classNames(css.navigationLink, currentPageClass('NewListingPage'))} name="NewListingPage">
          <span className={css.icon}>
            <IconCollection icon="camIcon" />
          </span>
          <FormattedMessage id="TopbarMobileMenu.newListingLink" />
        </NamedLink>
        <div className={css.faqsec}>
          <div className={css.faqwrapper}>
            <Accordion allowZeroExpanded={true}>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <div className={css.accordionHeader}>
                      <span className={css.icon}>
                        <IconCollection icon="IconAccount" className={css.iconAccount} />
                      </span>
                      <>Account</>
                    </div>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <div style={{ marginTop: '20px' }}>
                    <NamedLink
                      className={classNames(
                        css.navigationLinkAccount,
                        currentPageClass('ProfileSettingsPage')
                      )}
                      name="ProfileSettingsPage"
                    >
                      <FormattedMessage id="TopbarMobileMenu.profileSettingsLink" />
                    </NamedLink>
                    <NamedLink
                      className={classNames(
                        css.navigationLinkAccount,
                        currentPageClass('ProfileSettingsPage')
                      )}
                      name="ProfileSettingsPage"
                    >
                      <FormattedMessage id="TopbarMobileMenu.accountSettingsLink" />
                    </NamedLink>
                    <InlineTextButton
                      rootClassName={classNames(css.navigationLinkAccount, css.logoutButton)}
                      onClick={onLogout}
                    >
                      <FormattedMessage id="TopbarMobileMenu.logoutLink" />
                    </InlineTextButton>
                  </div>
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          </div>
        </div>
        <div className={classNames(css.profileSettingDiv)}>
          <div className={css.profileSettingsContent}>
            <div className={css.userName}>{userName}</div>

            <div className={css.starRatings}>
              <StarRatings
                svgIconViewBox="0 0 40 37"
                svgIconPath="M20 0L26.113 11.5862L39.0211 13.8197L29.891 23.2138L31.7557 36.1803L20 30.4L8.2443 36.1803L10.109 23.2138L0.97887 13.8197L13.887 11.5862L20 0Z"
                starRatedColor="#ffb802"
                // starEmptyColor="#ffffff"
                rating={calculateAverage(reviews)}
                starDimension="25px"
                starSpacing="2px"
              />
              <span
                className={css.reviewText}
                onClick={() => this.setState({ isReviewOpen: true })}
              >
                (<FormattedMessage id="ProfilePage.reviews" values={{ count: reviews.length }} />)
              </span>
            </div>
            <div className={css.salesAndFollowersInfo}>
              <span>{currentUserSaleLength} Purchases |</span>
              <span style={{ cursor: 'pointer' }}>
                {followers?.length === 1 ? (
                  <>
                    <FormattedMessage
                      id="ProfilePage.follower"
                      values={{ count: followers?.length }}
                    />{' '}
                    &nbsp;
                  </>
                ) : (
                  <>
                    {' '}
                    &nbsp;{' '}
                    <FormattedMessage
                      id="ProfilePage.followers"
                      values={{ count: followers?.length }}
                    />
                  </>
                )}
              </span>
              &nbsp;|&nbsp;
              <span style={{ cursor: 'pointer' }}>
                <FormattedMessage
                  id="ProfilePage.following"
                  values={{ count: following?.length }}
                />
              </span>
            </div>
            <div>
              {currentUserHasListings ? (
                <div>
                  <div className={classNames(css.buttonSeller, css.buttonSeller2)}>
                    <span className={css.availableEarnings}>
                      <FormattedMessage id="TopbarDesktop.availableEarning" /> &nbsp;
                      <span className={css.totalAmount}>${totalAmount / 100}USD</span>
                    </span>
                    <span className={css.arrowRight}>
                      <IconCollection icon="iconArrowDownWhite" />
                    </span>
                  </div>
                  <div className={css.storeItems}>
                    <div>
                      <span>
                        Sold Items ({currentUserSaleLength})
                      </span>
                    </div>
                    {!!currentUser && (
                      <div>
                        <NamedLink name="ProfilePage" params={{ id: currentUser.id.uuid }}>
                          <FormattedMessage id="TopbarDesktop.viewStore" />
                        </NamedLink>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div className={css.buttonSeller}>
                  <NamedLink name="NewListingPage">
                    <span>
                      <FormattedMessage id="TopbarDesktop.becomeSeller" />
                    </span>
                    <span className={css.arrowRight}>
                      <IconCollection icon="iconArrowDownWhite" />
                    </span>
                  </NamedLink>
                </div>
              )}
            </div>
          </div>
          <div className={css.category}>
            {listingStores.map(store => {
              return (
                <div key={store.key}>
                  <div className={css.iconWrapper}>
                    <span className={css.icon}>
                      <img src={store.icon} alt="icon" />
                    </span>
                  </div>
                  <span>{store.label}</span>
                </div>
              );
            })}
          </div>
        </div>

        {/* <AvatarLarge className={css.avatar} user={currentUser} /> */}
        <div className={css.spacer} />
      </div>
      
    </div>
  );
};

TopbarMobileMenu.defaultProps = { currentUser: null, notificationCount: 0, currentPage: null };

const { bool, func, number, string } = PropTypes;

TopbarMobileMenu.propTypes = {
  isAuthenticated: bool.isRequired,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
};

export default TopbarMobileMenu;
